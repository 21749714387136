import React from 'react';
import { Box, Button, Grid, Text, useMantineTheme } from '@mantine/core';
import { t } from 'i18next';
import { GridHeaderProps, SectionTitleProps } from '../../types';
import { ConfigRow } from '../../components/Account/SystemConfigInputs';

const GridHeader: React.FC<GridHeaderProps> = ({ spans, headers }) => (
  <Grid>
    {headers.map((header, index) => (
      <Grid.Col key={index} span={spans[index]}>
        <Text fw={600}>{t(header)}</Text>
      </Grid.Col>
    ))}
  </Grid>
);

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => (
  <Text mt="lg" mb="sm" fw={700}>
    {t(title)}
  </Text>
);

const SystemConfig: React.FC = () => {
  const theme = useMantineTheme();

  const gridHeaders: { [key: string]: GridHeaderProps } = {
    default: { spans: [3, 2.5, 4], headers: ["KEY", "VALUE", "DECORATORS"] },
    displayOption: { spans: [3, 2.5], headers: ["KEY", "VALUE"] },
  };

  return (
    <Box p="xxxl" bg={theme.colors.grey[11]} h="100%" >
      <Grid mb="md">
        <Grid.Col span={8}>
          <Text fw={700} size="0.875rem">
            {t("POSITION_SUMMARY")}
          </Text>
        </Grid.Col>
        <Grid.Col span={3.5}>
          <Button bg={theme.colors.violet[8]} w="6.6875rem" h="1.875rem">
            {t("UPDATE")}
          </Button>
        </Grid.Col>
      </Grid>

      <GridHeader {...gridHeaders.default} />
      {["UNIT", "CURRENCY_UPPERCASE", "PERCENTAGE"].map((label) => (
        <ConfigRow key={label} label={label} showDecorator={true} />
      ))}

      <SectionTitle title="SYSTEM" />
      <GridHeader {...gridHeaders.default} />
      {["TIMEZONE", "LANGUAGE", "QUANTITY_UPPERCASE", "CURRENCY_UPPERCASE", "CURRENCY_2", "PERCENTAGE"].map((label) => (
        <ConfigRow key={label} label={label} showDecorator={true}/>
      ))}

      <SectionTitle title="DISPLAY_OPTION" />
      <GridHeader {...gridHeaders.displayOption} />
      {["POSITION_SUMMARY_UPPERCASE", "ORDER"].map((label) => (
        <ConfigRow key={label} label={label}/>
      ))}
    </Box>
  );
};

export default SystemConfig;
