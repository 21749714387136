import { Box, Skeleton } from '@mantine/core'

const SkeletonLoader = () => {
  return (
    <Box pt="xl">
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
    </Box>
  )
}

export default SkeletonLoader