import type { CustomNoRowsOverlayProps } from '@ag-grid-community/react';
import React from 'react';

export default (props: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }) => {
    return (
        <div
        role="presentation"
        className='custom-message'
    >
        {props.noRowsMessageFunc()}
    </div>

    
    );
};