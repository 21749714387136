import {
	Button,
	Container,
	useMantineTheme,
	Text,
	TextInput,
	Card,
	Box,
  Grid,
} from "@mantine/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import classes from "../LoginPage/LoginPage.module.css";
import axios from "axios";
import { CalendarComponent } from "../../components/AllFundQuery/CalendarComponent";
import "./DemoStarter.css";
import { services } from "../../services";
import { options } from "./DemoStarterUtils";
import { KeyValuePairs } from "../../types";
import { format } from "date-fns";
import Select from "../../components/Common/Select/Select";

const DemoStarter = () => {
	const theme = useMantineTheme();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// Initialization fields
	const [initIndex, setInitIndex] = useState("");
	// Initialization fields


	// SOD Recon fields
	const [recIndex, setRecIndex] = useState("");
	// SOD Recon fields


	// Cashflow fields
	const [fundCode, setFundCode] = useState<string>("");
	const [amount, setAmount] = useState<string>("");
	const [mode, setMode] = useState("");
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	// Cashflow fields


	// Benchmark fields
	const [benchmark, setBenchmark] = useState("");
	// Benchmark fields

	
	// Market Data fields
	const [marketData, setMarketData] = useState("");
	// Market Data fields

	const submitCashflowEvent = async () => {
		try {
			setIsLoading(true);
			const response = await axios.post(`${services.DEMO_STARTER}?fund_id=${fundCode}`, {
				date: formatDate(selectedDate),
				amount: parseFloat(amount),
				target_mode: mode,
			});

			// Show success notification
			toast.success(`Cashflow entry successfully submitted for fund ${fundCode}.`);

			console.log("MODE:", response.data);
		} catch (error) {
			// Check if the error is an object with a 'message' property
			if (error instanceof Error && error.message) {
				const messageToShow = error.message.includes("400") ? "Cashflow data is incomplete or incorrect."
				: error.message.includes("401") ? "Authentication failed."
				: error.message.includes("403") ? `User is not authorized to access fund ${fundCode}`
				: error.message;
				// Show error notification
				toast.error(messageToShow);
				console.error("Error:", error);
			} else {
				// Fallback for unknown error structure
				toast.error("An unknown error occurred");
				console.error("Unknown Error:", error);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const formatDate = (date: Date | null ) => {
		if (!date) return null;
		return format(date, "yyyy-MM-dd");
	};

	const submitEvent = async (event_type: string, value: string) => {
		try {
			if(!event_type || !value) throw new Error('Event data is incomplete.')
			setIsLoading(true);
			const response = await axios.post(`${services.POST_EVENT}`, {
				event_type: event_type,
				event_value: value,
			});

			// Show success notification
			toast.success(`Event type '${event_type}' with value '${value}' submitted successfully`);

			console.log("Response", response);
			
		} catch (error) {
			// Check if the error is an object with a 'message' property
			if (error instanceof Error && error.message) {
				const messageToShow = error.message.includes("400") ? "Event is incomplete or incorrect."
				: error.message.includes("401") ? "Authentication failed."
				: error.message.includes("403") ? "User is not authorized"
				: error.message;
				// Show error notification
				toast.error(messageToShow);
				console.error("Error:", error);
			} else {
				// Fallback for unknown error structure
				toast.error("An unknown error occurred");
				console.error("Unknown Error:", error);
			}
		} finally {
			setIsLoading(false);
		}
	}

	type field<t> = {
		name: string,
		value: t,
		onChange: (value: t) => void,
		label: string,
		type: "input" | "select" | "date" | "button",
		options?: KeyValuePairs[],
	}

	// const formFields: field<string>[] = [
	const cashflowFields: field<any>[] = [
		{ name: "fundCode", value: fundCode, onChange: setFundCode, label: "Fund Code", type: "input" },
		{ name: "tradeDate", value: selectedDate, onChange: setSelectedDate, label: "Trade Date", type: "date" },
		{ name: "amount", value: amount, onChange: setAmount, label: "Amount", type: "input" },
		{ name: "mode", value: mode, onChange: setMode, label: "Mode", type: "select", options: options.cashflowMode },
	];

	const initializationFields: field<any>[] = [
		{ name: "initIndex", value: initIndex, onChange: setInitIndex, label: "", type: "select", options: options.initIndex },
	];

	const reconFields: field<any>[] = [
		{ name: "recIndex", value: recIndex, onChange: setRecIndex, label: "", type: "select", options: options.recIndex },
	];

	const benchmarkFields: field<any>[] = [
		{ name: "benchmark", value: benchmark, onChange: setBenchmark, label: "", type: "select", options: options.benchmark },
	];

	const marketFields: field<any>[] = [
		{ name: "marketData", value: marketData, onChange: setMarketData, label: "", type: "select", options: options.marketData },
	];

	// function getFormField (props: field<any>) {
	function getFormField (props: any) {
		const { name, value, onChange, label, type } = props;
		if (type === "input") {
			return (
				<TextInput
					value={value}
					onChange={(e) => onChange(e.target.value)}
					name={name}
					placeholder={label}
					size={"xs"}
				/>
			);
		} else if (type === "select") {
			return (
				<Select
					placeholder={label}
					data={props.options}
					name={name}
					value={value}
					onChange={(value) => onChange(value)}
				/>
			);
		} else if(type === "date") {
			return (
				<CalendarComponent
					placeholder={label}
					onSelect={onChange}
				/>
			);
		}
		return null
	}

  const forms = [
    { title: "Initialization", fields: initializationFields, onClick: () => submitEvent('initialization', initIndex) },
    { title: "SOD Recon", fields: reconFields, onClick: () => submitEvent('sod_recon', recIndex) },
    { title: "Cashflow", fields: cashflowFields, onClick: submitCashflowEvent },
    { title: "Benchmark", fields: benchmarkFields, onClick:  () => submitEvent('benchmark', benchmark) },
    { title: "Market Data", fields: marketFields, onClick:  () => submitEvent('market_data', marketData) },
  ]

	return (
		<Container fluid className={classes.gridstyles}>
			<Card
				radius='lg'
				w="75rem"
				h="42rem"
				className="card-container"
        p="3rem"
				pl="14.5rem"
        pr="14.5rem"
				bg={theme.colors.yellow[1]}
			>
				<Box className="demo-form">
          <Grid columns={16} w={"46rem"} gutter="xl">
            {
              forms.map((form) => {
                return (<>
                  <Grid.Col span={3}>
                    <Text size='1rem' fw='700' ta='right' pb='lg'>
                      {form.title}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8.5}>
                    <Grid columns={2} gutter="sm">
                    {form.fields.map((field, index) => {
                      return (<Grid.Col span={1} key={index} className="demo-field">
                        {getFormField(field)}
                      </Grid.Col>)
                    })}
                    </Grid>
                  </Grid.Col>
                  <Grid.Col span={4} offset={0.5}>
                    <Button
                      bg={theme.colors.violet[8]}
                      radius='sm'
                      onClick={form.onClick}
                      w="11rem"
                      h="1.75rem"
                      size="0.8125rem"
                      fw={700}
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={16} />
                </>)
              })
            }
          </Grid>
				</Box>
			</Card>
			<ToastContainer position='top-right' autoClose={5000} />
		</Container>
	);
};

export default DemoStarter;
