import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Text, Flex, Select, useMantineTheme } from '@mantine/core';
import axios from 'axios';
import '../AllFundQuery/SavePresetModal.css'; // Make sure to create this CSS file for styles
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';
import { services } from '../../services';

// Define the structure of your API response for targets
interface Target {
    type: string;
    target_category: string;
    lower_threshold: number;
    upper_threshold: number;
    target_name: string;
    target_value: number;
}

// Define the structure of the API response for templates
interface TemplateApiResponse {
    data: {
        template_name: string;
        targets: Target[];
    }[];
    message: string;
    api_status: string;
}

// Extend the props interface to include any other props needed
interface CustomModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose }) => {
    const [funds, setFunds] = useState<string[]>([]);
    const [templates, setTemplates] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [selectedFundId, setSelectedFundId] = useState<string | null>(null);
    const [selectedTargets, setSelectedTargets] = useState<Target[]>([]);
    const theme = useMantineTheme();

    // Fetch funds
    useEffect(() => {
        const fetchFunds = async () => {
            try {
                const response = await axios.get(services.TARGETS);
                setFunds(response.data.data.map((fund: any) => fund.fund_id));
            } catch (error) {
                console.error('Error fetching funds:', error);
            }
        };

        fetchFunds();
    }, []);

    // Fetch templates
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await axios.get<TemplateApiResponse>(services.GET_TEMPLATES);
                const uniqueTemplates = Array.from(new Set(response.data.data.map(template => template.template_name)));
                setTemplates(uniqueTemplates);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        fetchTemplates();
    }, []);

    const fetchTemplateDetails = async () => {
        if (selectedTemplate) {
            try {
                const response = await axios.get<TemplateApiResponse>(
                    `${services.GET_TEMPLATES}?template_name=${selectedTemplate}`
                );
                setSelectedTargets(response.data.data[0]?.targets || []);
            } catch (error) {
                console.error('Error fetching template details:', error);
            }
        }
    };
    useEffect(() => {
        fetchTemplateDetails();
    }, [selectedTemplate]);

    // Handle Apply button click
    const handleApply = async () => {
        if (selectedFundId && selectedTemplate && selectedTargets.length > 0) {
            const payload = [{
                fund_id: selectedFundId,
                ref_template_name: selectedTemplate,
                targets: selectedTargets
            }];

            try {
                const response = await axios.post(services.TARGETS, payload);
                if (response.data.api_status === 'success') {
                    console.log('Data successfully applied');
                } else {
                    console.error('Error applying template to fund:', response.data.message);
                }
                await onClose()
            } catch (error) {
                console.error('Error posting fund targets:', error);
            }
        } else {
            console.error('Please select both a template and a fund');
        }
    };

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            centered
            withCloseButton={false}
            radius="30px"
            className="modal"
            p={'xl'}
            size="md"
        >
            <Text ta={'center'} size='lg' fw={600} mt={"md"}>
                {t("APPLY_TEMPLATE")}
            </Text>

            {/* Dropdown for unique template names */}
            <Select
                placeholder="Select a template"
                data={templates}
                value={selectedTemplate}
                onChange={setSelectedTemplate}
                variant='transparent'
                mt='xl'
                className='presetText'
                w='22.75rem'
                ml={'1.5rem'}
            />

            {/* Dropdown for unique fund IDs */}
            <Select
                placeholder="Select a fund ID"
                data={funds}
                value={selectedFundId}
                onChange={setSelectedFundId}
                variant='transparent'
                mt='xl'
                className='presetText'
                w='22.75rem'
                ml={'1.5rem'}
            />

            <Flex justify="center" align="center" mt="xl" mb={"2.2rem"}>
                <CustomButton variant='cancel' onClick={onClose}>
                    {t("CANCEL")}
                </CustomButton>
                <CustomButton variant='submit' onClick={handleApply}>
                    {t("APPLY")}
                </CustomButton>
            </Flex>
        </Modal>
    );
};

export default CustomModal;
