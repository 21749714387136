export const formatDueDate = (dueDate: string | number | Date) => {
  const today = new Date();
  const targetDate = new Date(dueDate);
  const timeDiff = targetDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff === 0) return 'Today';
  if (daysDiff === 1) return 'Tomorrow';
  if (daysDiff > 1 && daysDiff <= 3) return `+${daysDiff}`;
  return `Later (${targetDate.toLocaleDateString()})`; 
};

export const getCurrentUTCDate = (date: Date | null = new Date()): string | null => {
  if (!date) return null;
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
