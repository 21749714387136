import React from 'react';
import './ComingSoon.css'; // Create this CSS file for styling
import PageTitle from '../../../components/Common/PageTitle';
import IconWrapper from '../../../components/Common/IconWrapper';
import { t } from 'i18next';

const ComingSoon: React.FC = () => {
    return (
        <div className="coming-soon">
            <div className="coming-soon__content">
                <div className="coming-soon__icon"><IconWrapper name="star-mark" /></div>
                <h1 className="coming-soon__title">{t("COMING_SOON")}</h1>
                <p className="coming-soon__text">{t("COMING_SOON_MESSAGE")}</p>
            </div>
        </div>
    );
}

export default ComingSoon;