import { Flex } from "@mantine/core";
import "./RecentPresets.css";
import { RecentPresetsProps } from "../../types";
import { useEffect, useState } from "react";
import { 
  IconReportSearch,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const getFolderName = (uuid: string) => {
  const storedData = localStorage.getItem('presetName');

  if (storedData) {
    const data = JSON.parse(storedData);
    let presetItem = null;
    for (const folder of data) {
      presetItem = folder.documents.find((item: { uuid: string }) => item.uuid === uuid);
      if (presetItem) break;
    }
    return presetItem?.name || uuid
  }
  return "N-A";
}

export const RecentPresets = ({
  selectPreset,
  openPreset,
  showError,
}: RecentPresetsProps) => {
  const [recentPresets, setRecentPresets] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setRecentPresets((localStorage.getItem("presets") || "").split(","));
  }, []);

  return (
    <Flex direction="column" className="recent-presets" gap="2rem" mt='-6.5rem'>
      <Flex align="center" direction="column">
        <IconReportSearch className="recent-presets-icon" />
      </Flex>
      <Flex align="center" direction="column">
        <h2 className="recent-presets-title">{showError ? t("NO_FUND_TITLE") : t("RECENT_PRESET_TITLE")}</h2>
        {showError ? <div>{t("NO_FUND_MESSAGE")}</div> : <></>}
        <div>{t("RECENT_PRESET_MESSAGE")} <span className="preset-link" onClick={() => openPreset()}>Presets.</span></div>
      </Flex>

      {recentPresets.join(",") && (
        <Flex align="center" direction="column">
          <div>
            <div className="sub-heading">Recently Used Presets:</div>
            <ul>
              {recentPresets.map((preset: string) => preset ? <li onClick={() => selectPreset(preset)} key={preset}>{getFolderName(preset)}</li> : <></>)}
            </ul>
          </div>
        </Flex>
      )}
    </Flex>
  )
}