import React from 'react';
import { Modal, Text, Flex } from '@mantine/core';
import { DeleteModalUserProps } from '../../types';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';

const DeleteModalUser: React.FC<DeleteModalUserProps> = ({ 
  opened, 
  onClose, 
  modalType, 
  TITLE, 
  message, 
  onSubmit, 
  centered = true, 
  withCloseButton = false, 
  radius = '30px', 
  className = 'modal' 
}) => {
  return (
    <Modal
      opened={opened && modalType === 'delete'}
      onClose={onClose}
      centered={centered}
      withCloseButton={withCloseButton}
      radius={radius}
      className={className}
    >
      <Text ta={'center'} size='lg' fw={600} mt={"md"}>{t(TITLE)}</Text>
      <Text ta={'center'} mt={"2.0625rem"}>{t(message)}</Text>
      <Flex justify="center" align="center" mt="xl" mb={"2.2rem"}>
        <CustomButton variant={'cancel'} onClick={onClose} children={"CANCEL"} />
        <CustomButton variant='submit' onClick={onSubmit} children={"DELETE"} />
      </Flex>
    </Modal>
  );
};

export default DeleteModalUser;
