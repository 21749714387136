import { PureComponent, createRef } from "react";

interface TickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string | number;
  };
  color: {
    text: string;
    backgroundColor: string;
  },
}

interface TickState {
  textWidth: number;
}

export default class Tick extends PureComponent<TickProps, TickState> {
  textRef = createRef<SVGTextElement>();

  state = {
    textWidth: 0,
  };

  componentDidMount() {
    this.updateTextWidth();
  }

  componentDidUpdate() {
    this.updateTextWidth();
  }

  updateTextWidth() {
    if (this.textRef.current) {
      const textWidth = this.textRef.current.getBBox().width;
      if (this.state.textWidth !== textWidth) {
        this.setState({ textWidth });
      }
    }
  }

  render() {
    const { x, y, payload, color } = this.props;
    const { textWidth } = this.state;

    console.log({payload});

    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x={-textWidth - 17}
          y={-10}
          width={textWidth + 14}
          height={20}
          fill={color.backgroundColor}
          rx={10}
          ry={10}
        />
        <text
          ref={this.textRef}
          x={-10}
          y={-10}
          dy={14}
          textAnchor="end"
          fill={color.text}
        >
          {payload?.value}
        </text>
      </g>
    );
  }
}
 