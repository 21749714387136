import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "../Agtablehomepage/Agstyles.css";
import "./PositionSummary.css";
import { useTranslation } from "react-i18next";
import { PositionSummaryTableData } from "../../types";
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';

export const PositionTable = ({ data, TITLE }: { data: PositionSummaryTableData[], TITLE: string }) => {
    const { t, i18n } = useTranslation();
    const languageKey = i18n.language;

    const colDefs: ColDef[] = [
        { headerName: "", field: "index", width: 70, type: "numericColumn" },
        { headerName: t("Asset Name"), field: "asset" },
        { headerName: t("Share before Orders"), field: "before", type: "numericColumn" },
        { headerName: t("Share After Orders"), field: "after", type: "numericColumn" },
        { headerName: t("Reason"), field:"reason", flex: 1, type: 'rightAligned' }
    ]
    
    const customHeight = () => {
        if (data.length > 1) {
            const heightInRem = 2.1 + (data.length * 1.875);
            return `${heightInRem}rem`;
        } else {
            return "4rem";
        }
    };

    const formatTitle = (TITLE:string) => `NO_DATA_${TITLE.toUpperCase().replaceAll(" ", "_")}`
    
    const noRowsOverlayComponentParams = {
        noRowsMessageFunc: () => `- ${t(formatTitle(TITLE))} -`,
    };

    return (
        <div
            className="position-summary-table ag-theme-quartz"
            style={{
                width: "100%",
                maxHeight: "13.125rem",
                minHeight: "4rem",
                height: customHeight(),
            }}
        >
            <AgGridReact   
                key={languageKey}
                rowData={data.map((row, index) => ({index: index + 1, ...row}))}
                columnDefs={colDefs}
                rowHeight={30}
                noRowsOverlayComponent={CustomNoRowsOverlay}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            />
        </div>
    );
};
