import React, { useCallback, useEffect } from 'react';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { SelectionData, SelectionHandlerProps } from '../../types';

const SelectionHandler: React.FC<SelectionHandlerProps> = ({ gridApiRef, setIsRowSelected }) => {
    const handleSelectionChanged = useCallback((event: SelectionChangedEvent) => {
        const gridApi = gridApiRef.current;
        if (!gridApi) return;
        const selectedRowsElement = document.querySelector("#selectedRows");

        if (!selectedRowsElement) {
            console.error("Element with ID 'selectedRows' not found.");
            setIsRowSelected(false); 
            return;
        }
        
        const selectedNodes = gridApi.getSelectedNodes();
        if (selectedNodes.length <= 0) {
            selectedRowsElement.innerHTML = "";
            setIsRowSelected(false); 
            return;
        }

        const selectedNode = selectedNodes[0];
        const userId = selectedNode.data.id;
        const fundGroup = selectedNode.data.fund_group;
        const name = userId || fundGroup ; 
        selectedRowsElement.innerHTML = name;
        setIsRowSelected(true);
        console.log("Selected Row:", selectedNode);

    }, [gridApiRef, setIsRowSelected]);

    useEffect(() => {
        const gridApi = gridApiRef.current;
        if (gridApi) {
            gridApi.addEventListener('selectionChanged', handleSelectionChanged);
            handleSelectionChanged({} as SelectionChangedEvent); 
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener('selectionChanged', handleSelectionChanged);
            }
        };
    }, [gridApiRef, handleSelectionChanged]);

    return <span id='selectedRows' className='selectedRow'></span>;
};

export default SelectionHandler;
