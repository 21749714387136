import { KeyValuePairs, OrdersDecoratorProps } from "../../types";

export const ordersDecorator = ({orders, initialTotalSellAmountInMillions, initialTotalBuyAmountInMillions} : OrdersDecoratorProps) => {
  //TODO: after confirming, need to add order_as_adv_percentage, as we are not getting any value for average_daily_volume
  let countSell = 0, countBuy = 0;
  const ordersList = orders.map((row) => {
    const isSell = row.transaction_type === "Sell";
    if(isSell) countSell++;
    else countBuy++;
    const total_amount = isSell ? -initialTotalSellAmountInMillions : initialTotalBuyAmountInMillions;
    return {
      ...row,
      amount_percentage: row.amount / total_amount * 100,
    }
  })
  return {
    ordersList,
    countBuy,
    countSell
  }
}

export const columnWidth: KeyValuePairs = {
  "asset_key.asset_id": 200,
  amount: 150,
  default: 130,
}