import { Grid, Text, TextInput } from "@mantine/core";
import { t } from "i18next";
import './AccountInfo.css';
import { SystemConfigInputsProps } from "../../types";

export const ConfigRow: React.FC<SystemConfigInputsProps> = ({ label, showDecorator = false }) => (
    <Grid align="center">
        <Grid.Col span={3} mt='xs'>
            <Text>{t(label)}</Text>
        </Grid.Col>
        <Grid.Col span={2.5}>
            <TextInput className="value-text-input" size="1.5625rem" />
        </Grid.Col>
        {showDecorator && (
            <Grid.Col span={4}>
                <TextInput className="decorator-text-input" size="1.5625rem" />
            </Grid.Col>
        )}
    </Grid>
);
