import { Card, Flex } from "@mantine/core";
import "./DateFilter.css";
import { format } from "date-fns";
import { DateFilterProps } from "../../../types";

const DateFilter = ({ data, selectedDate, onClick }: DateFilterProps) => {
  return (
    <Flex className="date-filter-container">
      {
        data.map(({ date, text, datePrefix }, key) => {
          const isSelected = selectedDate === date;
          return (
            <Card
              key={key}
              shadow={isSelected ? "sm" : "none"}
              className={`date-card${isSelected ? " selected" : ""}`}
              withBorder
              onClick={isSelected ? () => {} : () => onClick(date)}
            >
              <div>
                <strong>{datePrefix}</strong>
                <span>{format(date, "M/dd EEE")}</span>
              </div>
              <div>{text}</div>
            </Card>
          )
        })
      }
    </Flex>

  )
}

export { DateFilter }