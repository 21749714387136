import { Box } from '@mantine/core';
import { CashTabs } from './CashTabs';

function CashExposure() {
  return (
    <Box>
      <CashTabs />
    </Box>
  );
}

export default CashExposure;
