import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
	PERSIST,
	persistReducer,
	persistStore,
	PURGE,
	REGISTER,
	FLUSH,
  PAUSE,
  REHYDRATE
} from "redux-persist";
import userSlice from './userSlice';
import marketSlice from './marketSlice';

const combinedReducers = combineReducers({
	user: userSlice,
	market: marketSlice
})

const persistConfig = {
  key: 'root_v1',
  storage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
	reducer: persistedReducer,
	
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
		}),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch