import React from 'react';
import { Button, useMantineTheme } from '@mantine/core';

interface ContextButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const ContextButton: React.FC<ContextButtonProps> = ({
  onClick,
  children,
}) => {
  const theme = useMantineTheme();

  return (
    <Button
      onClick={onClick}
      style={{
        height: theme.spacing.lg,
        backgroundColor: theme.colors.grey[2],
        marginBottom: theme.spacing.xs,
        color: theme.colors.grey[8],
      }}
    >
      {children}
    </Button>
  );
};

export default ContextButton;
