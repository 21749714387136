import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Flex, Text } from '@mantine/core';
import CustomButton from '../components/Common/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../components/Common/PageTitle';
import { t } from 'i18next';
import SodReconTable from '../components/SODRecon/SodReconTable';
import { TableName } from '../constants';
import IconWrapper from '../components/Common/IconWrapper';

const SODRecon: React.FC = () => {
    const [COACTableData, setCOACTableData] = useState<any[]>([]);
    const [OrderTableData, setOrderTableData] = useState<any[]>([]);
    const [OthersTableData, setOthersTableData] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [loading, setLoading] = useState({ COAC: true, Order: true, Others: true });
    const { fund_group } = useParams();
    const navigate = useNavigate();

    const fetchData = async (
        resultType: string,
        setData: React.Dispatch<React.SetStateAction<any[]>>,
        loadingKey: keyof typeof loading
    ) => {
        setLoading(prev => ({ ...prev, [loadingKey]: true }));
        try {
            const response = await axios.get(
                `/api/fund-holdings-recon-work-items?fund_recon_result_type=${resultType}&fund_group=${fund_group}`
            );
            const formattedData = response.data.data.map((item: any) => {
                const { work_item_status, ...rest } = item;
                return {
                    id: item._id,
                    fund_id: item.alert?.alert_detail?.fund_id || '',
                    fund_name: item.alert?.alert_detail?.fund_name || '',
                    work_item_status: t(work_item_status) || 'Unknown Status',
                    asset_name: item.alert?.alert_detail?.asset_name || '',
                    benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
                    asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
                    our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
                    custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
                    explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
                    unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
                    recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
                    ...rest,
                };
            });
            setData(formattedData);
        } catch (error) {
            console.error(`Error fetching data for result type: ${resultType}`, error);
        } finally {
            setLoading(prev => ({ ...prev, [loadingKey]: false }));
        }
    };

    useEffect(() => {
        fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH', setCOACTableData, 'COAC');
        fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH', setOrderTableData, 'Order');
        fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_OTHERS_MISMATCH', setOthersTableData, 'Others');
    }, []);

    const allTablesLoaded = !Object.values(loading).some((isLoading) => isLoading);

    const handleCreateAdjustment = () => {
        if (selectedRows.length > 0) {
            const selectedAssetId = selectedRows[0]?.asset_id;
            const resultTypeMap = {
                'COAC': 'FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH',
                'Order': 'FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH',
                'Others': 'FUND_RECON_RESULT_TYPE_HOLDINGS_OTHERS_MISMATCH',
            };

            const resultType =
                COACTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['COAC'] :
                OrderTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['Order'] :
                OthersTableData.some(row => row.id === selectedRows[0]?.id) ? resultTypeMap['Others'] : '';

            if (selectedAssetId) {
                navigate(`/adjustment/${selectedAssetId}/${fund_group}/${resultType}`, { state: { selectedRows } });
            } else {
                console.error('Asset ID not found in selected row');
            }
        } else {
            console.error('No rows selected');
        }
    };

    const handleIgnore = async () => {
        if (selectedRows.length === 0) {
            console.error('No rows selected to ignore');
            return;
        }
        const completeRowsToSend = selectedRows.map((selectedRow) => {
            const allData = [...COACTableData, ...OrderTableData, ...OthersTableData];
            return allData.find(item => item.id === selectedRow.id);
        }).filter(item => item);

        try {
            await axios.post('/api/work-items/no-issue', completeRowsToSend);
            fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH', setCOACTableData, 'COAC');
            fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH', setOrderTableData, 'Order');
            fetchData('FUND_RECON_RESULT_TYPE_HOLDINGS_OTHERS_MISMATCH', setOthersTableData, 'Others');
        } catch (error) {
            console.error('Error ignoring work items:', error);
        }
    };

    const tableSetters: Record<TableName, React.Dispatch<React.SetStateAction<any[]>>[]> = {
        COACTable: [setOrderTableData, setOthersTableData],
        OrderTable: [setCOACTableData, setOthersTableData],
        OthersTable: [setCOACTableData, setOrderTableData],
    };

    const handleSelectionChange = (selected: React.SetStateAction<any[]>, tableName: TableName) => {
        setSelectedRows(selected);
        tableSetters[tableName].forEach(setter => {
            setter(prev => prev.map(row => ({ ...row, selected: false })));
        });
    };

    return (
        <Box>
            <PageTitle TITLE={t('SOD_RECON')} subtitle="HOLDING_CHECK" back full_report="FULL_REPORT" />
            <Flex direction="column" gap="sm" p="2rem">
                {allTablesLoaded ? (
                    <>
                        <Flex justify="flex-end">
                            <CustomButton variant="discard" onClick={handleIgnore}>
                                {t('IGNORE')}
                            </CustomButton>
                            <CustomButton variant="create-workitems" onClick={handleCreateAdjustment}>
                                {t('CREATE_ADJUSTMENT')}
                            </CustomButton>
                        </Flex>

                        <Text size="lg" fw="600">
                            {t('COAC_RELATED')} ({COACTableData.length || 0})
                        </Text>
                        <SodReconTable data={COACTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'COACTable')} allowMultipleSelection={false} />

                        <Text size="lg" fw="600" mt="xl">
                            {t('ORDER_RELATED')} ({OrderTableData.length || 0})
                        </Text>
                        <SodReconTable data={OrderTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'OrderTable')} allowMultipleSelection={true} />

                        <Text size="lg" fw="600" mt="xl">
                            {t('OTHERS')} ({OthersTableData.length || 0})
                        </Text>
                        <SodReconTable data={OthersTableData} onSelectionChange={(selected) => handleSelectionChange(selected, 'OthersTable')} allowMultipleSelection={true} />
                    </>
                ) : (
                    <Flex justify={'center'} align={'center'}>
                        <IconWrapper name='loading-large' />
                    </Flex>
                )}
            </Flex>
        </Box>
    );
};

export default SODRecon;
