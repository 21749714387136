export const calculateColorShade = (
    fieldValue: number,
    violatedTolerance: number,
    theme: any 
  ): string => {
    const difference = Math.abs(fieldValue - violatedTolerance);
    const maxDifference = 0.5;
    const percentageDifference = (difference / maxDifference) * 100;
  
    if (percentageDifference > 50) {
      return theme.colors.red[0];
    } else {
      return theme.colors.red[1];
    }
  };