import React, { useMemo, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../Agtablehomepage/Agstyles.css";
import { useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { AllFundQueryTableProps } from "../../types";
import { AgGridEvent, ColDef } from "ag-grid-community";
import { formatHeaderUtil } from "../../utils/formatHeaderUtil";

interface AllFundQueryTablePropsExtended extends AllFundQueryTableProps {
    isDisplayedFromHoldingContentTab?: boolean;
}

const AllFundQueryTable: React.FC<AllFundQueryTableProps & { isFromHoldingContentTab?: boolean }> = ({ data, isFromHoldingContentTab }) => {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const tableWrapperRef = useRef<HTMLDivElement>(null);

    const columnDefs = useMemo(() => {
        if (data.length === 0) return [];

        const keys = Object.keys(data[0]);

        const columns = keys.map((key, index) => {
            const isBooleanField = typeof data[0][key] === 'boolean';

            const columnDefinition: ColDef = {
                headerName: formatHeaderUtil(key),
                field: key,
                headerClass: "custom-header",
                filter: "agTextColumnFilter",
                width: key === "sr_no" ? 90 : undefined,
                valueGetter: key === "sr_no" ? "node.rowIndex + 1" : undefined,
                pinned: ["sr_no", "bloomberg_ticker", "asset_name","fund_id", "fund_name"].includes(key)  ? "left": undefined,
                cellRenderer: isBooleanField ? (params: { value: any; }) => params.value ? "true" : "false" : undefined
            };

            return columnDefinition;
        });

        const lastColumnIndex = columns.length - 1;
        const totalFixedWidth = columns.reduce((total, column) => (column.width ? total + column.width : total), 0);
        const remainingWidth = 100 + totalFixedWidth;
        columns[lastColumnIndex] = {
            ...columns[lastColumnIndex],
            width: remainingWidth,
        };

        return columns;
    }, [data]);


    const customHeight = () => {
        if (data.length > 1) {
            const heightInRem = 1.876 + data.length * 1.876 + 0.65;
            return `${heightInRem}rem`;
        } else {
            return "4.4rem";
        }
    };

    useEffect(() => {
        if (tableWrapperRef.current) {
            const cells = tableWrapperRef.current.querySelectorAll(".ag-cell-value");
            let maxContentWidth = 0;
            cells.forEach((cell) => {
                maxContentWidth = Math.max(maxContentWidth, cell.clientWidth);
            });
            tableWrapperRef.current.style.minWidth = `${maxContentWidth}px`;
        }
    }, [data]);

    const maxHeight = isFromHoldingContentTab ? "83vh" : "23.125rem";

    return (
        <div
            ref={tableWrapperRef}
            className='fund-query ag-theme-quartz'
            style={{
                height: customHeight(),
                maxHeight: maxHeight,
                width: "100%",
                minHeight: "4.4rem",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            {data.length > 0 ? (
                <AgGridReact columnDefs={columnDefs} rowData={data} rowHeight={30}  suppressRowHoverHighlight={true} onSortChanged={(e: AgGridEvent) => {
                    e.api.refreshCells();
                }} />
            ) : (
                <div> </div>
            )}
        </div>
    );
};

export default AllFundQueryTable;


