import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AllFundQueryTable from '../../components/AllFundQuery/AllFundQueryTable';
import { Center, Flex } from '@mantine/core';
import ErrorMessage from '../../components/Common/ErrorMessage';
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../components/Common/Skeleton';
import { services } from '../../services';
import IconWrapper from '../../components/Common/IconWrapper';

export const HoldingContentTab: React.FC = () => {
  const [data, setData] = useState<any>(null);
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fund_id, transaction_id, target_date, calc_date } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading indicator
      try {
        const response = await axios.get(services.ASSETS, {
          params: {
            fund_id,
            target_date: target_date,
            calc_date: calc_date,
            transaction_id
          }
        });
        if (response.status === 200) {
          const error_message = response.data.message;
          if (response.data.data === null) {
            setError(error_message);
          } else {
            setData(response.data.data);
            console.log("orders: ", mapData(response.data?.data, services.ASSETS))
            setOrders(mapData(response.data?.data, services.ASSETS));
          }
        } else {
          setError('Error fetching data. Please try again later.');
        }
      } catch (error: any) {
        setError(error.response.status);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fund_id, transaction_id, target_date]);

  const mapData = (data: any[] | undefined, endpoint: string) => {
    console.log("Mapping data for endpoint:", endpoint);
    if (!Array.isArray(data)) {
      console.error("Invalid data format. Expected an array.");
      return [];
    }
  
    return data.map((item: any, index: number) => {
      const asset = item.asset;
 
      if (!asset) {
        console.warn("Item missing asset", item);
        return null; 
      }
  
      const mappedItem: any = {
        sr_no: index + 1,
      };
      const quantities = item.asset_quantities;

      mappedItem.fund_id = asset.fund_id;
      mappedItem.calc_date = asset.calc_date;
      mappedItem.bloomberg_ticker = asset.asset_key.bloomberg_ticker;
      mappedItem.currency = asset.asset_attributes.currency;
      mappedItem.price_date = asset.asset_prices.price_date;
      mappedItem.price = asset.asset_prices.prices_for_price_type.close_price;
      mappedItem.average_daily_volume = asset.asset_prices.average_daily_volume;
      mappedItem.min_trade_size = asset.asset_prices.min_trade_size;
      mappedItem.min_trade_increment = asset.asset_prices.min_trade_increment;
      mappedItem.source = asset.asset_prices.source;
      mappedItem.asset_group = asset.asset_group;
      mappedItem.asset_name = asset.asset_attributes.asset_name;
      mappedItem.issue_country = asset.asset_attributes.issue_country;
      mappedItem.trading_country = asset.asset_attributes.trading_country;
      mappedItem.risk_country = asset.asset_attributes.risk_country;
      mappedItem.sector = asset.asset_attributes.sector;

      if (quantities && quantities.length > 0) {
        const quantity = quantities[0];
        if (quantity.asset_group === 'EQUITY') {
          mappedItem.total_quantity = quantity.equity.total_quantity;
          mappedItem.traded_quantity = quantity.equity.traded_quantity;
          mappedItem.settled_quantity = quantity.equity.settled_quantity;
          mappedItem.substituted_in_quantity = quantity.equity.substituted_in_quantity;
          mappedItem.substituted_out_quantity = quantity.equity.substituted_out_quantity;
          mappedItem.adjusted_quantity = quantity.equity.adjusted_quantity;
          mappedItem.indirectly_held_quantity = quantity.equity.indirectly_held_quantity;
          mappedItem.on_loan_quantity = quantity.equity.on_loan_quantity;
          mappedItem.approved_orders_quantity = quantity.equity.approved_orders_quantity;
          mappedItem.proposed_orders_quantity = quantity.equity.proposed_orders_quantity;
          mappedItem.target_date = quantity.target_date;
        } else if (quantity.asset_group === 'DERIVATIVES') {
          mappedItem.total_quantity = quantity.futures.total_quantity;
          mappedItem.traded_quantity = quantity.futures.traded_quantity;
          mappedItem.settled_quantity = quantity.futures.settled_quantity;
          mappedItem.substituted_in_quantity = quantity.futures.substituted_in_quantity;
          mappedItem.substituted_out_quantity = quantity.futures.substituted_out_quantity;
          mappedItem.adjusted_quantity = quantity.futures.adjusted_quantity;
          mappedItem.indirectly_held_quantity = quantity.futures.indirectly_held_quantity;
          mappedItem.on_loan_quantity = quantity.futures.on_loan_quantity;
          mappedItem.approved_orders_quantity = quantity.futures.approved_orders_quantity;
          mappedItem.proposed_orders_quantity = quantity.futures.proposed_orders_quantity;
          mappedItem.target_date = quantity.target_date;
        }
      }
  
      return mappedItem;
    }).filter(Boolean); 
  };
  
  return (
    <Flex mt={19} className='holdingtab-main-container'>
      {loading ? (
        <Flex align='center' justify='center' w='100%'>
          <IconWrapper name='loading-large' />
        </Flex>
      ) : error ? (
        <Flex><ErrorMessage errorMessage={error} errorCode={error} /></Flex>
      ) : (
        <AllFundQueryTable data={orders} isFromHoldingContentTab={true} />
      )}
    </Flex>
  );
};

export default HoldingContentTab;
