import React from 'react';
import { Box, Card, Flex, Menu, Portal, useMantineTheme } from '@mantine/core';
import ContextMenuButton from './ContextButton'; // Adjust the path as per your project structure
import "./Preset.css"

interface ContextMenuProps {
  visible: boolean;
  x: number;
  y: number;
  actionTextRename: string,
  actionTextDelete: string,
  actionRename: Function,
  actionDelete: Function
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  visible,
  x,
  y,
  actionTextRename,
  actionTextDelete,
  actionRename,
  actionDelete
}) => {
  const theme = useMantineTheme();

  if (!visible) return null;
  return (
    <Portal>
      <div className="context-menu-container" style={{ top: y, left: x }}>
        <Menu opened={visible}>
          <Card
          radius="lg"
            style={{
              backgroundColor: theme.colors.grey[2],
              height: '4rem',
              width: '6.5rem',
              padding: theme.spacing.xxs,
              borderRadius: theme.radius.lg,
              
            }}
          >
            <Flex align='center' justify='center' direction='column' mt='0.25rem'>
            <ContextMenuButton onClick={() => actionRename()}>
              {actionTextRename}
            </ContextMenuButton>
           
            <ContextMenuButton onClick={() => actionDelete()}>{actionTextDelete}</ContextMenuButton>
            </Flex>
          </Card>
        </Menu>
      </div>
    </Portal>
  );
};

export default ContextMenu;
