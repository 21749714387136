import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, GridOptions } from 'ag-grid-community';
import { t } from 'i18next';
import { services } from '../../services';
import axios from 'axios';
import { ActionIcon, Box, Card, Flex, Grid, Modal, TextInput, Text, Checkbox, useMantineTheme } from '@mantine/core';
import IconWrapper from '../Common/IconWrapper';
import { Role, RoleTableProps } from '../../types';
import "../../pages/AdminPages/Users/AdminPage.css"
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import { useDisclosure } from '@mantine/hooks';
import SkeletonLoader from '../Common/Skeleton';
import DeleteModalUser from './DeleteModalUser';
import SelectionHandler from './SelectionHandler';
import AdminTableHeader from './AdminTableHeader';
import CustomButton from '../Common/Buttons/CustomButton';
import { handleMainContentClickUtil } from '../../utils/handleMainContentClick';
import { useDeselectAllOnRowUnselect } from '../../utils/useDeselectAllOnRowUnselect';

const RoleTable: React.FC<RoleTableProps> = ({ apiData, roleCounts, onUniqueDescriptionsChange }) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState(true);
    const theme = useMantineTheme();
    const [modalType, setModalType] = useState<null | 'create' | 'update' | 'delete'>(null);
    const [modalOpened, { toggle: toggleModal }] = useDisclosure(false);
    const [selectedRole, setSelectedRole] = useState<Role | null>(null);
    const [roleName, setRoleName] = useState('');
    const [checkedCanApprove, setCheckedCanApprove] = useState(false);
    const [checkedCoac, setCheckedCoac] = useState(false);
    const [ordersLimit, setOrdersLimit] = useState('');
    const [permissions, setPermissions] = useState<string[]>([]);
    const [userToDelete, setUserToDelete] = useState<{ name: string } | null>(null);
    const [isRowSelected, setIsRowSelected] = useState(false);

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleModalToggle = (type: 'create' | 'update' | 'delete') => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            toggleModal();
        } else {
            setModalType(type);
            if (!modalOpened) toggleModal();
        }
    };

    const createRole = () => {
        const data = {
            description: roleName,
            permissions: permissions,
            can_approve_orders: checkedCanApprove ? 'true' : 'false',
            can_approve_coac: checkedCoac ? 'true' : 'false',
            orders_limit: ordersLimit,
        };

        axios.post(services.ROLES, data)
            .then(response => {
                console.log('Role created successfully:', response.data);
                handleModalToggle('create')
                fetchRoles();
            })
            .catch(error => {
                console.error('Error creating role:', error);
            });
    };

    const handleCreateRoleClick = () => {
        setRoleName('');
        setCheckedCanApprove(false);
        setCheckedCoac(false);
        setOrdersLimit('');
        setPermissions([]);
        setModalType('create');
        handleModalToggle('create')
    };

    const openEditModal = (role: Role) => {
        setSelectedRole(role);
        setRoleName(role.description);
        setCheckedCanApprove(role.can_approve_orders);
        setCheckedCoac(role.can_approve_coac);
        setOrdersLimit(role.orders_limit);
        setPermissions(role.permissions);
        setModalType('update');
        handleModalToggle('update')
    };

    const fetchRoles = () => {
        axios.get(services.ROLES)
            .then(response => {
                setRoles(response.data);
                setLoading(false);

                const uniqueDescriptions: Role[] = Array.from(new Set(response.data.map((role: Role) => role.role)))
                    .map((roleName) => {
                        // Find the first role object with this roleName
                        const roleObject = response.data.find((role: Role) => role.role === roleName);
                        if (roleObject) {
                            return {
                                role: roleName,
                                description: roleObject.description
                            };
                        }
                        return null;
                    })
                    .filter((role): role is Role => role !== null);

                // const uniqueDescriptions = Array.from(new Set(response.data.map((role: Role) => role.description)));
                onUniqueDescriptionsChange(uniqueDescriptions);


            })
            .catch(error => {
                console.error('Error fetching roles:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const colDefs: ColDef[] = [
        { headerName: t('ROLE'), field: 'description', sort: 'asc' },
        {
            headerName: t('CAN_APPROVE'),
            field: 'can_approve',
            cellRenderer: (params: { data: any; }) => {
                const { can_approve_orders, can_approve_coac, orders_limit } = params.data;
                return (() => {
                    if (can_approve_orders && can_approve_coac) {
                        return `< $${orders_limit} ${t("LOWERCASE_ORDERS")}, ${t("COAC_DEALS")}`;
                    }
                    if (can_approve_orders) {
                        return `< $${orders_limit} ${t("LOWERCASE_ORDERS")}`;
                    }
                    if (can_approve_coac) {
                        return `< $${orders_limit} ${t("COAC_DEALS")}`;
                    }
                    return "-";
                })();
            }

        },
        {
            headerName: t("USER_COUNT"),
            field: 'roleCount',
            valueGetter: (params: { data: any; }) => roleCounts[params.data.description] || 0
        },
    ];

    const customHeight = () => {
        if (roles.length > 1) {
            const heightInRem = 2.1 + (roles.length * 1.87);
            return `${heightInRem}rem`;
        } else {
            return "3.8rem";
        }
    };

    const rowHeight = 30;
    const rowSelectionType = 'single';
    const gridApiRef = useRef<any>(null);

    const onGridReady = (params: { api: any; }) => {
        gridApiRef.current = params.api;
    };

    const handleSet = () => {
        if (selectedRole) {
            const data = {
                role: selectedRole.role,
                description: roleName,
                can_approve_orders: checkedCanApprove,
                can_approve_coac: checkedCoac,
                orders_limit: ordersLimit,
                permissions: permissions
            };

            axios.put(services.ROLES, data)
                .then(response => {
                    console.log('Role updated successfully:', response.data);
                    handleModalToggle('update')
                    setTimeout(() => {
                        fetchRoles()
                        setIsRowSelected(false); // Fetch updated data after a delay
                    }, 500);

                })
                .catch(error => {
                    console.error('Error updating role:', error);
                });
        }
    };

    const handleDeleteClick = () => {
        const gridApi = gridApiRef.current;
        const selectedNodes = gridApi.getSelectedNodes();
        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const name = selectedNode.data.role;
            setUserToDelete({ name: name });
            setModalType('delete');
            handleModalToggle('delete')

        }
    };

    const handleDeleteUser = () => {
        if (userToDelete) {
            const data = {
                name: userToDelete.name,
            };
            axios
                .delete(services.ROLES, { data })
                .then(response => {
                    console.log('User deleted successfully:', response.data);
                    handleModalToggle('delete')
                    fetchRoles()
                    setTimeout(() => {
                        setIsRowSelected(false);
                    }, 500);
                })
                .catch(error => {
                    console.error('Error deleting user:', error);
                });
        } else {
            console.error('No user selected for deletion.');
        }
    };

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_ROLES_HERE")} -`,
        };
    }, []);

    const gridOptions: GridOptions = {
        suppressRowHoverHighlight: true,
    };
    const handleEditClick = () => {
        const selectedNodes = gridApiRef.current.getSelectedNodes();
        if (selectedNodes.length > 0) {
            openEditModal(selectedNodes[0].data);
        }
    };

    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    const mainContentRef = useRef<HTMLDivElement>(null);
    const handleMainContentClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        handleMainContentClickUtil(e, mainContentRef, setIsRowSelected);
    };

    return (
        <Box w={'80rem'} ref={mainContentRef} 
        onClick={handleMainContentClick}>
            <Box maw={"51rem"}>
                <Box mr={'md'}>
                <AdminTableHeader isRowSelected={isRowSelected} handleEditUser={handleEditClick} handleDeleteClick={handleDeleteClick} resetCreateUser={handleCreateRoleClick} headerTitle="ROLES" createTitle='CREATE_USER_ROLE' />
                </Box>
                <Modal
                    opened={modalOpened && modalType === 'update'}
                    onClose={() => handleModalToggle('update')}
                    centered
                    withCloseButton={false}
                    radius={'30px'} className='modal'
                >
                    {selectedRole && (
                        <Card bg={theme.colors.grey[2]} p={"xl"}>
                            <Text fw={700} mt={"-0.5rem"} size='lg' ta={'center'}>{t("EDIT_ROLE")}</Text>
                            <Grid mt={'xxxl'} align='center'>
                                <Grid.Col span={3}>
                                    <Text>{t("ROLE")}</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <div className='textInput'>
                                        <TextInput
                                            value={roleName}
                                            onChange={(event) => setRoleName(event.currentTarget.value)}
                                        />
                                    </div>
                                </Grid.Col>
                            </Grid>

                            <Grid mt={'md'} align='center'>
                                <Grid.Col span={3}>
                                    <Text>{t("CAN_APPROVE")}</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>
                                    <Checkbox
                                        checked={checkedCanApprove}
                                        onChange={(event) => setCheckedCanApprove(event.currentTarget.checked)}
                                        color="gray"
                                        variant="outline"
                                    />
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Text size={theme.fontSizes.md} c={theme.colors.grey[8]}>{t("ORDERS")}</Text>
                                </Grid.Col>
                            </Grid>

                            <Grid mt={'md'}>
                                <Grid.Col span={3}></Grid.Col>
                                <Grid.Col span={4}>
                                    <div className='textInput'>
                                        <TextInput
                                            value={0}
                                            placeholder='min'
                                        />
                                    </div>
                                </Grid.Col>
                                <Grid.Col span={1} ta={'center'}>
                                    <Flex justify={'center'} ta={'center'} mt={'sm'}>
                                        <div className="line"></div>
                                    </Flex>
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <div className='textInput'>
                                        <TextInput
                                            value={ordersLimit}
                                            onChange={(event) => setOrdersLimit(event.currentTarget.value)}
                                            placeholder='max'
                                        />
                                    </div>
                                </Grid.Col>
                            </Grid>

                            <Grid mt={'md'} align='center'>
                                <Grid.Col span={3}></Grid.Col>
                                <Grid.Col span={1}>
                                    <Checkbox
                                        checked={checkedCoac}
                                        onChange={(event) => setCheckedCoac(event.currentTarget.checked)}
                                        color="gray"
                                        variant="outline"
                                    />
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Text size={theme.fontSizes.md} c={theme.colors.grey[8]}>{t("COAC_DEALS")}</Text>
                                </Grid.Col>
                            </Grid>

                            <Flex justify="flex-end" mt="2.5rem" mb={"lg"}>
                                <CustomButton variant={'cancel'} onClick={() => handleModalToggle('update')} children='CANCEL' />
                                <CustomButton variant={'submit'} onClick={handleSet} children='SET' />
                            </Flex>
                        </Card>
                    )}
                </Modal>

                <Modal opened={modalOpened && modalType === 'create'} onClose={() => handleModalToggle('create')} centered
                    withCloseButton={false} radius={'30px'} className='modal'>
                    <Card bg={theme.colors.grey[2]} p={"xl"}>

                        <Text fw={700} mt={"-0.5rem"} size='lg' ta={'center'}> {t("CREATE_NEW_ROLE")}</Text>
                        <Grid mt="lg" align='center'>
                            <Grid.Col span={3}>
                                <Text>{t("ROLE")}</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <div className='textInput'>
                                    <TextInput value={roleName} onChange={event => setRoleName(event.currentTarget.value)} />
                                </div>
                            </Grid.Col>
                        </Grid>

                        <Grid mt="lg" align='center'>
                            <Grid.Col span={3}>
                                <Text>{t("CAN_APPROVE")}</Text>
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <Checkbox
                                    checked={checkedCanApprove}
                                    onChange={event => setCheckedCanApprove(event.currentTarget.checked)}
                                    color="gray"
                                    variant="outline"
                                />
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <Text size={theme.fontSizes.md} c={theme.colors.grey[8]}>{t("ORDERS")}</Text>
                            </Grid.Col>
                        </Grid>

                        <Grid mt="lg">
                            <Grid.Col span={3}></Grid.Col>
                            <Grid.Col span={4}>
                                <div className='textInput'>
                                    <TextInput placeholder={t("MIN")} />
                                </div>
                            </Grid.Col>
                            <Grid.Col span={1} ta={'center'}>
                                <Flex justify={'center'} ta={'center'} mt={'sm'}>
                                    <div className="line"></div>
                                </Flex>
                            </Grid.Col>

                            <Grid.Col span={4}>
                                <div className='textInput'>
                                    <TextInput placeholder={t("MAX")} value={ordersLimit} onChange={event => setOrdersLimit(event.currentTarget.value)} />
                                </div>
                            </Grid.Col>
                        </Grid>

                        <Grid mt="lg" align='center'>
                            <Grid.Col span={3}></Grid.Col>
                            <Grid.Col span={1}>
                                <Checkbox
                                    checked={checkedCoac}
                                    onChange={event => setCheckedCoac(event.currentTarget.checked)}
                                    color="gray"
                                    variant="outline"
                                />
                            </Grid.Col>
                            <Grid.Col span={8}>
                                <Text size={theme.fontSizes.md} c={theme.colors.grey[8]}>{t("COAC_DEALS")}</Text>
                            </Grid.Col>
                        </Grid>

                        <Flex justify="flex-end" mt="2.5rem" mb={"lg"}>
                            <CustomButton variant={'cancel'} onClick={() => handleModalToggle('create')} children='CANCEL' />
                            <CustomButton variant={'submit'} onClick={createRole} children='CREATE' />
                        </Flex>
                    </Card>
                </Modal>

                <SelectionHandler gridApiRef={gridApiRef} setIsRowSelected={setIsRowSelected} />
                <DeleteModalUser
                    opened={modalOpened}
                    onClose={() => handleModalToggle('delete')}
                    modalType={modalType}
                    TITLE='DELETE_ROLE'
                    message='DELETE_ROLE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />

                <div className='action-item ag-theme-quartz'
                    style={{
                        height: customHeight(),
                        width: "95%",
                        maxHeight: "23.125rem",
                        minHeight: "3.8rem",
                        marginTop: "0.7rem"
                    }}
                >
                    {loading ? (
                        <p><SkeletonLoader /></p>
                    ) : (
                        <>
                            <AgGridReact
                                rowData={roles}
                                columnDefs={colDefs}
                                defaultColDef={{ flex: 1, minWidth: 100 }}
                                rowSelection={rowSelectionType}
                                rowHeight={rowHeight}
                                onGridReady={onGridReady}
                                onSelectionChanged={handleSelectionChanged}
                                noRowsOverlayComponent={CustomNoRowsOverlay}
                                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                gridOptions={gridOptions}
                                rowMultiSelectWithClick={true}
                            />
                        </>
                    )}
                </div>
            </Box>
        </Box>
    );
};

export default RoleTable;