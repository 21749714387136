import React from 'react';
import { Flex, useMantineTheme } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';
import { EquitizeTableProps } from '../../types';

const EquitizeTable: React.FC<EquitizeTableProps> = ({ title, rowData, columnDefs, height }) => {
    const theme = useMantineTheme();

    return (
        <Flex justify={'center'}>
            <div
                className="fund-query ag-theme-quartz"
                style={{ height, width: "52rem", minHeight: "4.4rem", padding: '1rem' }}
            >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowHeight={30}
                    suppressRowHoverHighlight={true}
                    getRowStyle={(params) => {
                        const isLastRow = params.node.rowIndex === rowData.length - 1;
                        if (
                            (title === 'SpendableCashTable' ||
                             title === 'MarginCashTable' ||
                             title === 'AccuredCashTable') && 
                             isLastRow
                        ) {
                            return { backgroundColor: theme.colors.grey[0] };
                        }
                        return undefined;
                    }}
                />
            </div>
        </Flex>
    );
};

export default EquitizeTable;
