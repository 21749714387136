import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import SodReconTable from './SodReconTable';
import { Box, Flex, Text } from '@mantine/core';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';
import axios from 'axios';
import PageTitle from '../Common/PageTitle';
import { getCurrentUTCDate } from '../../utils/dateUtils';
import { AdjustmentPayload, SelectedDates } from '../../types';
import { services } from '../../services';
import CustomAddModal from '../Common/CustomAddModal';
import SodReconTableComplete from './SodReconTableComplete';

const AdjustmentPage: React.FC = () => {
    const location = useLocation();
    const { asset_id, fund_group, result_type } = useParams();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState(location.state?.selectedRows || []);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState<SelectedDates>({});
    const [mismatchTableData, setMismatchTableData] = useState<any[]>([]);
    const [selectedMismatchRows, setSelectedMismatchRows] = useState<any[]>([]);
    const [currentSelectedRows, setCurrentSelectedRows] = useState<any[]>([]);
    const [applyToValues, setApplyToValues] = useState<{ [key: number]: string }>(
        Object.fromEntries(selectedRows.map((_: any, index: any) => [index, 'Custodian']))
    );
    const defaultDate = new Date();
    const [startDates, setStartDates] = useState<{ [key: number]: Date | null }>(
        Object.fromEntries(selectedRows.map((_: any, index: any) => [index, defaultDate]))
    );
    const [endDates, setEndDates] = useState<{ [key: number]: Date | null }>(
        Object.fromEntries(selectedRows.map((_: any, index: any) => [index, defaultDate]))
    );

    const selectedId = location.state?.selectedIds;
    const startingDate = location.state?.startingDate;
    const endingDate = location.state?.endingDate;

    useEffect(() => {
        if (startingDate && endingDate && selectedId) {
            setStartDates((prev) => ({ ...prev, [selectedId]: new Date(startingDate) }));
            setEndDates((prev) => ({ ...prev, [selectedId]: new Date(endingDate) }));
        }
    }, [startingDate, endingDate, applyToValues, selectedId]);

    useEffect(() => {
        const fetchMismatchData = async () => {
            try {
                const response = await axios.get(
                    `${services.FUND_HOLDINGS}?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`
                );
                const selectedIds = new Set(selectedRows.map((row: any) => row.id));
                const filteredData = response.data.data.filter(
                    (item: any) => !selectedIds.has(item.id)
                );
                const formattedData = filteredData.map((item: any) => {
                    const { work_item_status, ...rest } = item;
                    return {
                        id: item._id,
                        fund_id: item.alert?.alert_detail?.fund_id || '',
                        fund_name: item.alert?.alert_detail?.fund_name || '',
                        work_item_status: t(work_item_status) || 'Unknown Status',
                        asset_name: item.alert?.alert_detail?.asset_name || '',
                        benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
                        asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
                        our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
                        custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
                        explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
                        unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
                        recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
                        ...rest,
                    };
                });
                setMismatchTableData(formattedData || []);
            } catch (error) {
                console.error('Error fetching mismatch data:', error);
            }
        };
        if (selectedRows.length > 0) {
            fetchMismatchData();
        }
    }, [selectedRows, asset_id, fund_group, result_type]);


    const handleDateChange = (rowId: string, date: Date | null, isStartDate: boolean) => {
        if (isStartDate) {
            setStartDates((prev) => ({ ...prev, [rowId]: date }));
        } else {
            setEndDates((prev) => ({ ...prev, [rowId]: date }));
        }
    };

    const createPayload = (rows: typeof currentSelectedRows, startDate: string, endDate: string) =>
        rows.map((row, index) => ({
            fund_id: row.fund_id || "",
            "asset_key.asset_id": row.asset_id || index.toString() || "",
            adj_sub_type: row.adj_sub_type || "FUND_ADJUSTMENT",
            start_date: startDate,
            end_date: endDate,
            target_asset_id: row.asset_id || index.toString() || "",
        }));

    const COACcall = async (url: string, payload: any, description: string) => {
        console.log(`Making API call for ${description}`);
        const response = await axios.post(url, payload, {
            headers: { 'Content-Type': 'application/json' },
        });
        console.log(`${description} API Success:`, response.data);
        return response;
    };

    const handleApiCallsAndNavigation = async (apiCalls: Promise<any>[], allSelectedRows: typeof currentSelectedRows) => {
        const workItemsApiUrl = services.FIXED_ISSUE;
        const workItemResponse = await axios.post(workItemsApiUrl, allSelectedRows, {
            headers: { 'Content-Type': 'application/json' },
        });
        console.log('Work Item API Success:', workItemResponse.data);
    
        const selectedIds = allSelectedRows.map(row => row.id);
        navigate(`${location.pathname}/completed`, {
            state: {
                adjustmentData: allSelectedRows,
                selectedIds,
                startDates,
                endDates,
                applyToValues,
            },
        });
        setIsModalOpen(false);
    };
    

    const createPayloadForHoldingsMismatch = async (isCustodian: boolean) => {
        const allSelectedRows = [...currentSelectedRows, ...selectedMismatchRows];
        const startDate = getCurrentUTCDate(startDates[0] || null) || "";
        const endDate = getCurrentUTCDate(endDates[0] || null) || "";
        const payload = createPayload(allSelectedRows, startDate, endDate);

        try {
            const apiUrl = `${services.FUND_ADJUSTMENT}?custodian=${isCustodian}`;
            const apiCalls = [COACcall(apiUrl, payload, isCustodian ? 'Custodian' : 'Atlantis')];
            await handleApiCallsAndNavigation(apiCalls, allSelectedRows);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.response?.data || error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        }
    };

    const createPayloadForHoldingsOrdersMismatch = async () => {
        const allSelectedRows = [...currentSelectedRows, ...selectedMismatchRows];
        const startDate = getCurrentUTCDate(startDates[0] || null) || "";
        const endDate = getCurrentUTCDate(endDates[0] || null) || "";

        const custodianRows = currentSelectedRows.filter((_, index) => applyToValues[index] === 'Custodian');
        const atlantisRows = currentSelectedRows.filter((_, index) => applyToValues[index] === 'Atlantis');
        const apiCalls: Promise<any>[] = [];

        if (custodianRows.length) {
            const custodianPayload = createPayload(custodianRows, startDate, endDate);
            apiCalls.push(COACcall(`${services.FUND_ADJUSTMENT}?custodian=true`, custodianPayload, 'custodian=true'));
        }
        if (atlantisRows.length) {
            const atlantisPayload = createPayload(atlantisRows, startDate, endDate);
            apiCalls.push(COACcall(`${services.FUND_ADJUSTMENT}?custodian=false`, atlantisPayload, 'custodian=false'));
        }
        if (selectedMismatchRows.length) {
            const additionalRowsPayload = createPayload(selectedMismatchRows, startDate, endDate);
            apiCalls.push(COACcall(services.FUND_ADJUSTMENT, additionalRowsPayload, 'additional rows'));
        }

        try {
            await handleApiCallsAndNavigation(apiCalls, allSelectedRows);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error:', error.response?.data || error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        }
    };

    const handleSaveAdjustment = async () => {
        const params = new URLSearchParams(location.search);
        console.log('Result Type:', result_type);

        if (result_type === 'FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH') {
            await createPayloadForHoldingsOrdersMismatch();
        } else if (result_type === 'FUND_RECON_RESULT_TYPE_HOLDINGS_COAC_MISMATCH') {
            // Convert `applyToValues` to an array and check for any "Custodian" values
            const isCustodian = Object.values(applyToValues).some(value => value === 'Custodian');
            await createPayloadForHoldingsMismatch(isCustodian);
        }
    };



    return (
        <Box>
            <PageTitle
                TITLE='SOD_RECON'
                subtitle='HOLDING_CHECK'
                subtitle2='ADJUSTING_QUANTITY'
                back
                full_report='FULL_REPORT'
            />
            <Flex direction="column" gap="sm" p="2rem">
                <Flex justify="flex-end">
                    <CustomButton variant="discard-recon" onClick={() => window.location.reload()}>
                        {t("DISCARD_RECON")}
                    </CustomButton>
                    <CustomButton
                        variant="create-workitems"
                        onClick={() => setIsModalOpen(true)}
                        disabled={!currentSelectedRows.length}
                    >
                        {t("SAVE_ADJUSTMENT")}
                    </CustomButton>
                </Flex>

                <SodReconTableComplete
                    data={selectedRows}
                    onSelectionChange={setCurrentSelectedRows}
                    isAdjustmentPage={true}
                    onDateChange={handleDateChange}
                    onApplyToChange={setApplyToValues}
                    applyToValues={applyToValues}
                />

                {mismatchTableData.length > 0 && (
                    <>
                        <Text size='lg' fw={600} mt={'lg'}>{t("SELECT_OTHER_FUND_COAC")}</Text>
                        <SodReconTable
                            data={mismatchTableData}
                            onSelectionChange={setSelectedMismatchRows}
                            allowMultipleSelection={true}
                        />
                    </>
                )}
            </Flex>

            <CustomAddModal
                opened={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={() => {
                    console.log('Submitting adjustments');
                    handleSaveAdjustment();
                }}
                title={"SAVE_ADJUSTMENT_TITLE"}
                message={"SAVE_ADJUSTMENT_MESSAGE"}
                submitButtonText={"SAVE"}
                cancelButtonText={"CANCEL"}
            />
        </Box>
    );
};

export default AdjustmentPage;
