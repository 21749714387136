type useLocalStorageReturnType = [string, (value: string) => void];

export const useLocalStorage = (key: string, defaultState: string) : useLocalStorageReturnType  => {
  if(!localStorage.getItem(key)) {
    localStorage.setItem(key, defaultState);
  }
  return [
    localStorage.getItem(key) || "",
    (value: string) => localStorage.setItem(key, value),
  ]
}