import React from 'react';
import { Flex, Text } from '@mantine/core';
import IconWrapper from '../Common/IconWrapper';
import { UserOnNavLinkProps } from '../../types';
import { IconSettings } from '@tabler/icons-react';

const UserOnNavLink: React.FC<UserOnNavLinkProps> = ({ user }) => {
  return (
    <Flex align="center" p="md">
      {/* <IconWrapper name="user" className='account-user-icon' /> */}
      <IconSettings size='2rem' stroke={1} className='setting-navlink'/>
      <Flex direction="column" ml="sm">
        <Text size="0.875rem" fw={700}>
          {user?.fullName || user?.username}
        </Text>
        <Text size="md">
          {user?.companyName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserOnNavLink;
