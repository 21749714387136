import { ActionIcon, Button, Flex, Group } from '@mantine/core';
import React from 'react';
import IconWrapper from '../Common/IconWrapper';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';

interface EditToggleButtonProps {
  isEditing: boolean;
  onToggle: () => void;
  onUpdate: () => void; 
  onDiscard: () => void; 
}

const EditToggleButton: React.FC<EditToggleButtonProps> = ({ isEditing, onToggle, onUpdate, onDiscard }) => {
  
  return (
    <Group>
      {!isEditing ? (
        <ActionIcon onClick={onToggle} variant='transparent' ml={'auto'} p={'lg'}>
          <IconWrapper name='editPen' />
        </ActionIcon>
      ) : (
        <Flex justify={'center'} align={'center'} ml={'auto'}>
          <ActionIcon variant='transparent'>
            <IconWrapper name='copyTemplate' className='copy-template'/>
          </ActionIcon>
          <CustomButton variant="discard" onClick={onDiscard}>
            {t("DISCARD_CHANGES")}
          </CustomButton>
          <CustomButton variant="update" onClick={onUpdate}>
           {t("UPDATE")}
          </CustomButton>
        </Flex>
      )}
    </Group>
  );
};

export default EditToggleButton;
