import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { routes } from '../../routes';
import { CashForecast, RowDataForeCast } from '../../types';

const ForeCast = () => {
    const [cashExposureData, setCashExposureData] = useState<CashForecast | null>(null);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [rowData, setRowData] = useState<RowDataForeCast[]>([]);
    const { fund_id, target_mode, target_date, calc_date } = useParams();

    useEffect(() => {
        axios
            .get(routes.CASHEXPOSURE, {
                params: {
                    fund_id,
                    calc_date,
                    target_date,
                    target_mode,
                },
            })
            .then((response) => {
                if (response.data.api_status === 'success' && response.data.data && response.data.data.length > 0) {
                    const forecastData = response.data.data[0].cash_forecast;
                    
                    if (forecastData && Object.keys(forecastData).length > 0) {
                        setCashExposureData(forecastData);
            
                        const newColumnDefs = generateColumnDefs(forecastData);
                        const newRowData = generateRowData(forecastData);
            
                        setColumnDefs(newColumnDefs);
                        setRowData(newRowData);
                    } else {
                        console.warn('No forecast data available.');
                    }
                } else {
                    console.warn('API returned success but no data was provided.');
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
            
    }, []);

    const generateColumnDefs = (forecastData: CashForecast) => {
        const columns: ColDef[] = [
            { headerName: 'Currency', field: 'currency_code', width: 100 },
        ];

        const allDates = Array.from(
            new Set(Object.values(forecastData).map((currency) => currency.sett_date))
        );

        allDates.forEach((date) => {
            const maxSpendableCash = Math.max(
                ...Object.values(forecastData)
                    .filter((currencyData) => currencyData.sett_date === date)
                    .map((currencyData) => currencyData.spendable_cash || 0)
            );

            columns.push({
                headerName: date,
                field: date,
                valueGetter: (params) => {
                    const currencyCode = params.data.currency_code;
                    return getFieldForDate(forecastData[currencyCode], date, 'spendable_cash');
                },
                cellRenderer: (params: { value: any; }) => {
                    const spendableCash = params.value;
                    const percentage = maxSpendableCash
                        ? ((spendableCash / maxSpendableCash) * 100).toFixed(2)
                        : '0.00';
                    const USDCash = new Intl.NumberFormat('en-US', {
                        currency: 'USD',
                    }).format(spendableCash);

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>{USDCash}</div>
                            <div style={{ marginLeft: '3rem' }}>{percentage}%</div>
                        </div>
                    );
                },
                width: 250,
            });
        });

        return columns;
    };


    const getFieldForDate = (currencyData: any, date: string, field: string) => {
        if (currencyData.sett_date === date) {
            return currencyData[field] || 0;
        }
        return 0;
    };

    const generateRowData = (forecastData: CashForecast) => {
        return Object.keys(forecastData).map((currencyCode) => {
            const row: RowDataForeCast = { currency_code: currencyCode };
            if (forecastData[currencyCode].hasOwnProperty('spendable_cash')) {
                row['spendable_cash'] = forecastData[currencyCode].spendable_cash;
            }

            return row;
        });
    };

    const customHeight = () => {
        if (rowData.length > 1) {
            const heightInRem = 4 + rowData.length * 1.9;
            return `${heightInRem}rem`;
        }
    };

    return (
        <div className="fund-query ag-theme-quartz" style={{ height: customHeight(), width: "23rem", minHeight: "4.4rem", padding: '1rem' }}>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                rowHeight={30}
                suppressRowHoverHighlight={true}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    resizable: true,
                }}
            />
        </div>
    );
};

export default ForeCast;
