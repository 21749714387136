import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { DateInput } from '@mantine/dates';
import { Select } from '@mantine/core';
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import { applyDecorators, getTableData } from "../../utils/conversions";
import { t } from 'i18next';
import { SodReconTableProps } from '../../types';
import './SodReconTable.css';

const SodReconTableComplete: React.FC<SodReconTableProps> = ({
  data,
  onSelectionChange,
  allowMultipleSelection = true,
  isAdjustmentPage = false,
  startDates: initialStartDates = {},
  endDates: initialEndDates = {},
  onDateChange,
  onApplyToChange,
  applyToValues,
  completeAdjustment = false,
  disableEdit = false,
}) => {
  const [rowData, setRowData] = useState<any[]>([]);
  const tableData = getTableData("SodReconTableCompleted");

  const defaultDate = new Date();

  const [startDates, setStartDates] = useState<{ [key: string]: Date | null }>(
    Object.keys(initialStartDates).length ? initialStartDates : { default: defaultDate }
  );

  const [endDates, setEndDates] = useState<{ [key: string]: Date | null }>(
    Object.keys(initialEndDates).length ? initialEndDates : { default: defaultDate }
  );

  const [applyTo, setApplyTo] = useState<{ [key: string]: string }>({
    ...applyToValues,
  });

  const handleDateChange = (rowId: string, date: Date | null, isStartDate: boolean) => {
    if (isStartDate) {
      setStartDates((prev) => ({ ...prev, [rowId]: date }));
    } else {
      setEndDates((prev) => ({ ...prev, [rowId]: date }));
    }

    if (onDateChange) {
      onDateChange(rowId, date, isStartDate);
    }
  };

  const handleApplyToChange = (rowId: string, value: string) => {
    setApplyTo((prev) => {
      const updatedApplyTo = { ...prev, [rowId]: value };

      if (onApplyToChange) {
        onApplyToChange(updatedApplyTo);
      }

      return updatedApplyTo;
    });
  };

  const renderDateInput = (rowId: string, date: Date | null, isStartDate: boolean) => (
    <DateInput
      value={date || defaultDate}
      onChange={(selectedDate) => handleDateChange(rowId, selectedDate, isStartDate)}
      placeholder={`Select ${isStartDate ? 'start' : 'end'} date`}
      key={`${isStartDate ? 'start' : 'end'}-${rowId}`}
      className="calendar-recon"
      style={{ backgroundColor: disableEdit ? 'white' : undefined }}
      disabled={disableEdit}
    />
  );

  const renderApplyToSelect = (rowId: string, value: string) => (
    <Select
      value={value || 'Custodian'}
      onChange={(selectedValue) => handleApplyToChange(rowId, selectedValue || 'Custodian')}
      data={[
        { value: 'Custodian', label: 'Custodian' },
        { value: 'Atlantis', label: 'Atlantis' },
      ]}
      className="calendar-recon"
      styles={{ dropdown: { backgroundColor: disableEdit ? 'white' : undefined } }}
      disabled={disableEdit}
    />
  );

  const columnDefs: ColDef[] = [
    ...(completeAdjustment
      ? []
      : [{
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
      }]),
    ...tableData.map((row) => {
      const decorators = row.decorators;

      if (isAdjustmentPage) {
        // Apply custom renderers for start_date, end_date, and apply_to fields
        if (row.key === 'start_date') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0 },
            cellRenderer: (params: ICellRendererParams) =>
              renderDateInput(params.node?.id || '', startDates[params.node?.id || ''], true),
          };
        }

        if (row.key === 'end_date') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0 },
            cellRenderer: (params: ICellRendererParams) =>
              renderDateInput(params.node?.id || '', endDates[params.node?.id || ''], false),
          };
        }

        if (row.key === 'apply_to') {
          return {
            headerName: t(row.colHeader),
            field: row.key,
            cellStyle: { paddingLeft: 0 },
            cellRenderer: (params: ICellRendererParams) =>
              renderApplyToSelect(params.node?.id || '', applyTo[params.node?.id || '']),
          };
        }
      }

      return {
        headerName: t(row.colHeader),
        field: t(row.key),
        valueFormatter: ({ value }: ValueFormatterParams) => applyDecorators(t(value), decorators),
      };
    }),
  ];

  useEffect(() => {
    setRowData(Array.isArray(data) ? data : []);
  }, [data]);

  const onSelectionChanged = (event: any) => {
    const selectedRows = event.api.getSelectedRows();

    if (onSelectionChange) {
      if (!allowMultipleSelection && selectedRows.length > 1) {
        event.api.deselectAll();
        event.api.getRowNode(selectedRows[selectedRows.length - 1].id).setSelected(true);
      }
      onSelectionChange(selectedRows);
    }
  };

  const noRowsOverlayComponentParams = useMemo(
    () => ({
      noRowsMessageFunc: () => `- ${t('NO_WORK_ITEMS_TO_SHOW')} -`,
    }),
    []
  );

  return (
    <div
      className="action-item ag-theme-quartz"
      style={{
        height: rowData.length > 1 ? `${1.876 + rowData.length * 1.9}rem` : '4rem',
        width: '100%',
        maxHeight: '23.125rem',
        minHeight: '4rem',
      }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        rowSelection={allowMultipleSelection ? 'multiple' : 'single'}
        rowHeight={30}
        onSelectionChanged={onSelectionChanged}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        suppressRowHoverHighlight={true}
      />
    </div>
  );
};

export default SodReconTableComplete;
