import React from 'react';
import '../../../components/Common/ComingSoon/ComingSoon.css';
import PageTitle from '../../../components/Common/PageTitle';
import { Box } from '@mantine/core';
import ComingSoon from '../../../components/Common/ComingSoon/ComingSoon';

const Support: React.FC = () => {
    return (
        <Box>
            <PageTitle TITLE='CUSTOMER_SUPPORT' />
            <ComingSoon />
        </Box>
    );
}

export default Support;