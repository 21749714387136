import React from 'react';
import { TextInput, useMantineTheme } from '@mantine/core'; // Adjust import based on your library

type FolderTextInputProps = {
    newFolderNameDelete?: boolean;
    newFolderName?: string;
    setNewFolderName?: (value: string) => void;
    handleDropLocalStorage: (event: React.DragEvent<HTMLDivElement>) => void;
    handleKeyDownDelete?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleBlurSaveDelete?: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const FolderTextInput: React.FC<FolderTextInputProps> = ({
    newFolderNameDelete,
    newFolderName = '',
    setNewFolderName,
    handleDropLocalStorage,
    handleKeyDownDelete,
    handleBlurSaveDelete,
    handleFocus,
}) => {
    const theme = useMantineTheme()
    return (
        <TextInput
            ml={'0.2rem'}
            mt={'0.2rem'}
            size={theme.fontSizes.md}
            c={theme.colors.grey[5]}
            className={newFolderNameDelete ? 'inputfolder' : 'dropfolder'}
            placeholder={
                newFolderNameDelete
                    ? 'Untitled Folder'
                    : (localStorage.getItem('deletedFolderName') !== 'default' ? localStorage.getItem('deletedFolderName') || '' : '')
            }
            value={newFolderNameDelete ? newFolderName : undefined}
            onDrop={handleDropLocalStorage}
            onDragOver={(event) => event.preventDefault()}
            onChange={newFolderNameDelete && setNewFolderName ? (event) => setNewFolderName(event.target.value) : undefined}
            onKeyDown={newFolderNameDelete ? handleKeyDownDelete : undefined}
            onBlur={newFolderNameDelete ? handleBlurSaveDelete : undefined}
            onFocus={newFolderNameDelete ? handleFocus : undefined}
            disabled={!newFolderNameDelete}
            autoFocus
        />
    );
};

export default FolderTextInput;
