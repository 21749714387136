import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Flex, Text } from '@mantine/core';
import PageTitle from '../Common/PageTitle';
import { useParams, useLocation } from 'react-router-dom';
import SodReconTable from './SodReconTable';
import { t } from 'i18next';
import IconWrapper from '../Common/IconWrapper';
import SodReconTableComplete from './SodReconTableComplete';
import { getCurrentUTCDate } from '../../utils/dateUtils';

interface ApplyToValue {
    label: string;
}

interface LocationState {
    startDates?: { [key: number]: Date };
    endDates?: { [key: number]: Date };
    applyToValues?: { label: string }[] | string;
    selectedIds?: string[];
}

const CompleteAdjustment = () => {
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [mismatchTableData, setMismatchTableData] = useState<any[]>([]);
    const [pollingCount, setPollingCount] = useState(0);
    const [loadingMismatchData, setLoadingMismatchData] = useState(true);
    const location = useLocation();
    const { asset_id, result_type, fund_group } = useParams();
    const selectedIds = (location.state as LocationState)?.selectedIds || [];

    const { startDates = {}, endDates = {}, applyToValues } = location.state as LocationState || {};

    useEffect(() => {
        const fetchSelectedRows = async () => {
            try {
                const response = await axios.get(
                    `/api/fund-holdings-recon-work-items?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`
                );
                const rows = response.data.data.filter((item: any) =>
                    selectedIds.includes(item.id) && item.work_item_status === 'WORK_ITEM_STATUS_CLOSED'
                );
                const applyToArray = typeof applyToValues === 'object' && applyToValues !== null
                    ? Object.values(applyToValues)
                    : [];
                const formattedRows = rows.map((row: any, index: number) => {
                    const formattedStartDate = startDates && startDates[index] ? getCurrentUTCDate(startDates[index]) : undefined;
                    const formattedEndDate = endDates && endDates[index] ? getCurrentUTCDate(endDates[index]) : undefined;
                    const formattedApplyToValue = applyToArray[index] || '';
                    return {
                        ...row,
                        start_date: formattedStartDate,
                        end_date: formattedEndDate,
                        apply_to: formattedApplyToValue,
                    };
                });
                setSelectedRows(formattedRows);
            } catch (error) {
                console.error('Error fetching selected rows:', error);
            }
        };

        if (pollingCount < 10) {
            const interval = setInterval(() => {
                fetchSelectedRows();
                setPollingCount((count) => count + 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [pollingCount]);

    useEffect(() => {
        const fetchMismatchData = async () => {
            setLoadingMismatchData(true);
            try {
                const response = await axios.get(
                    `/api/fund-holdings-recon-work-items?fund_recon_result_type=${result_type}&fund_group=${fund_group}&asset_id=${asset_id}`
                );
                const filteredData = response.data.data.filter(
                    (item: any) =>
                        !selectedIds.includes(item.id) && item.work_item_status === 'WORK_ITEM_STATUS_CLOSED'
                );
                const formattedData = filteredData.map((item: any) => {
                    const { work_item_status, ...rest } = item;
                    return {
                        id: item._id,
                        fund_id: item.alert?.alert_detail?.fund_id || '',
                        fund_name: item.alert?.alert_detail?.fund_name || '',
                        work_item_status: t(work_item_status) || 'Unknown Status',
                        asset_name: item.alert?.alert_detail?.asset_name || '',
                        benchmark_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.benchmark_id || '',
                        asset_id: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.asset_id || '',
                        our_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.fund_asset_quantity || '',
                        custodian_total_quantity: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.custodian_asset_quantity || '',
                        explained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.explained_difference || '',
                        unexplained_difference: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.quantity_after_adj?.unexplained_difference || '',
                        recon_priority: item.alert?.alert_detail?.sod_fund_recon_alert_detail?.fund_asset_holdings_recon_data?.recon_priority || '',
                        ...rest,
                    };
                });
                setMismatchTableData(formattedData || []);
            } catch (error) {
                console.error('Error fetching mismatch data:', error);
            } finally {
                setLoadingMismatchData(false);
            }
        };
        fetchMismatchData();
    }, [selectedIds, result_type, fund_group, asset_id]);

    return (
        <Box>
            <PageTitle
                TITLE='SOD_RECON'
                subtitle='HOLDING_CHECK'
                subtitle2='COMPLETED_ADJUSTMENT'
                back
                full_report='FULL_REPORT'
            />
            <Flex direction="column" gap="sm" p="2rem">
                {result_type === 'FUND_RECON_RESULT_TYPE_HOLDINGS_ORDERS_MISMATCH' ? (
                    <>
                        {selectedRows.length > 0 ? (
                            <SodReconTableComplete
                                data={selectedRows}
                                isAdjustmentPage={true}
                                completeAdjustment={true}
                            />
                        ) : (
                            <Flex justify={'center'} align={'center'}>
                                <IconWrapper name='loading-large' />
                            </Flex>
                        )}
                    </>
                ) : (
                    selectedRows.length > 0 && (
                        <>
                            <Text size='lg' fw={600} mt={'lg'}>{t("COMPLETED_COAC_ADJUSTMENT")}</Text>
                            <SodReconTableComplete
                                data={selectedRows}
                                completeAdjustment={true}
                            />
                        </>
                    )
                )}

                {loadingMismatchData || mismatchTableData.length === 0 ? (
                    <Flex justify={'center'} align={'center'}>
                        <IconWrapper name='loading-large' />
                    </Flex>
                ) : (
                    mismatchTableData.length > 0 && (
                        <>
                            <Text size='lg' fw={600} mt={'lg'}>{t("OTHER_FUND_APPLIED_COAC")}</Text>
                            <SodReconTable
                                data={mismatchTableData}
                                allowMultipleSelection={false}
                                completeAdjustment={true}
                            />
                        </>
                    )
                )}
            </Flex>
        </Box>
    );
};

export default CompleteAdjustment;
