import { useEffect, useRef } from "react";
import { useAppDispatch } from "../../store/hooks";

const POLLING_INTERVAL = 6000;

type Props = {
    callback: any;
    type: "thunk" | "func";
}

export const useInterval = ({ callback, type }: Props) => {
    const savedCallback = useRef<any | null >(null);
    const fetchCount = useRef(1);
    const dispatch = useAppDispatch();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])
    
    useEffect(() => {
		//silently fetch data after every 6s
		const intervalId = setInterval(() => {
			// console.log('isVisible ', !document.hidden);
			if (!document.hidden) {
				// console.log('fetch ', fetchCount.current)
                fetchCount.current++;
                if (savedCallback.current) {
                    if (type === "thunk")
                        dispatch(savedCallback.current);
                    else
                        savedCallback.current();
                }
            }
		}, POLLING_INTERVAL);

		return () => {
			clearInterval(intervalId);
		}
	}, [document.hidden, callback, POLLING_INTERVAL]);

}