import React from 'react';
import { TextInput, Stack, Flex, Text, Grid } from '@mantine/core';
import { t } from 'i18next';
import { useAppSelector } from '../../store/hooks';
import "../../components/Account/AccountInfo.css"

const DisabledTextInput: React.FC<{ label: string; value: string }> = ({ label, value }) => {
    return (
        <Grid align={'center'} >
            <Grid.Col span={1.5}p={3} >
                <Text mr={'xl'}>{t(label)}</Text>
            </Grid.Col>

            <Grid.Col span={10.5} p={3}>
                <TextInput
                    value={t(value)}
                    disabled
                    w='20rem'
                    size='1.5625rem'
                    className='account'
                />
            </Grid.Col>
        </Grid>
    );
};

const ActionInfo: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);

    const fields = [
        { label: 'NAME', value: user?.fullName || "" },
        { label: 'USER_ID', value: user?.username || "" },
        { label: 'EMAIL', value: user?.email || "" },
        { label: 'PASSWORD', value: '' },
        { label: 'COMPANY', value: '' },
        {
            label: 'ROLE',
            value: user?.roles ? (Array.isArray(user.roles) ? user.roles[0] : user.roles) : ''
        }
    ];

    return (
        <Stack m="md" p={'lg'}>
            {fields.map((field, index) => (
                <DisabledTextInput key={index} label={field.label} value={field.value} />
            ))}
        </Stack>
    );
};

export default ActionInfo;
