import React from 'react';
import { Modal, Text, Flex } from '@mantine/core';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';

interface CustomAddModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  message?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  modalType?: string;  // Optional modal type if needed for conditional modals
  centered?: boolean;
  withCloseButton?: boolean;
  radius?: string;
  className?: string;
}

const CustomAddModal: React.FC<CustomAddModalProps> = ({
  opened,
  onClose,
  onSubmit,
  title,
  message,
  submitButtonText = "SUBMIT",
  cancelButtonText = "CANCEL",
  modalType,
  centered = true,
  withCloseButton = false,
  radius = '30px',
  className = 'modal',
}) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered={centered}
      withCloseButton={withCloseButton}
      radius={radius}
      className={className}
    >
      <Text ta={'center'} size='lg' fw={600} mt={"md"}>
        {t(title)}
      </Text>
      {message && (
        <Text ta={'center'} mt={"2.0625rem"}>
          {t(message)}
        </Text>
      )}
      <Flex justify="center" align="center" mt="xl" mb={"2.2rem"}>
        <CustomButton variant={'cancel'} onClick={onClose}>
          {t(cancelButtonText)}
        </CustomButton>
        <CustomButton variant='submit' onClick={onSubmit}>
          {t(submitButtonText)}
        </CustomButton>
      </Flex>
    </Modal>
  );
};

export default CustomAddModal;