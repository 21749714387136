import { useEffect } from 'react';

type UseClickOutsideParams = {
  ref: React.RefObject<HTMLElement>;
  handler: () => void;
  condition: boolean;
};

const useClickOutside = ({ ref, handler, condition }: UseClickOutsideParams) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };

    if (condition) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handler, condition]);
};

export default useClickOutside;
