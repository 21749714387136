import React from 'react';

export const handleMainContentClickUtil = (
    e: React.MouseEvent<HTMLDivElement>,
    mainContentRef: React.RefObject<HTMLDivElement>,
    setIsRowSelected: (isSelected: boolean) => void
) => {
    const target = e.target as Node;

    if (
        mainContentRef.current &&
        !mainContentRef.current.querySelector('.ag-theme-quartz')?.contains(target) &&
        !mainContentRef.current.querySelector('.roles-table')?.contains(target)
    ) {
        setIsRowSelected(false);
    }
};
