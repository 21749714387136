import React, { useEffect, useState } from 'react';
import { Modal, Text, Flex, TextInput, useMantineTheme } from '@mantine/core';
import CustomButton from '../Common/Buttons/CustomButton';
import './SavePresetModal.css';
import { t } from 'i18next';
import CustomSelectBox from '../Common/Select/Select';

interface SavePresetModalProps {
    isOpen: boolean;
    onClose: () => void;
    presetName: string;
    setPresetName: (value: string) => void;
    handleSave: () => void;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    selectedFolderName: string;
    setSelectedFolderName: (value: string) => void;
}

const SavePresetModal: React.FC<SavePresetModalProps> = ({
    isOpen,
    onClose,
    presetName,
    setPresetName,
    handleSave,
    handleKeyPress,
    selectedFolderName,
    setSelectedFolderName,
}) => {
    const theme = useMantineTheme();
    const [folderOptions, setFolderOptions] = useState<{ value: string; label: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchFolderOptions = () => {
        try {
            const storedPresets = JSON.parse(localStorage.getItem('presetName') || '[]');
            const storedFolders = localStorage.getItem('folderName');

            const presetOptions = storedPresets.map((preset: { _id: string }) => ({
                value: preset._id,
                label: preset._id,
            }));

            // If storedFolders is a string, create a single option from it
            if (storedFolders) {
                presetOptions.push({
                    value: storedFolders,
                    label: storedFolders,
                });
            }

            if (presetOptions.length > 0) {
                setFolderOptions(presetOptions);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Failed to fetch presets or folders:', error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true); // Show loader while fetching
            fetchFolderOptions();
        }
    }, [isOpen]);

    return (
        <Modal
            opened={isOpen}
            onClose={onClose}
            centered
            withCloseButton={false}
            radius="30px"
            className="modal"
            p={'xl'}
            size="md"
        >
            <Text ta={'center'} size='lg' fw={600} mt={"md"}>
                {t("SAVE_AS_PRESET")}
            </Text>

            {!isLoading && (
                <CustomSelectBox
                    data={folderOptions}
                    value={selectedFolderName}
                    onChange={(value) => setSelectedFolderName(value || '')}
                    variant='transparent'
                    mt='xl'
                    placeholder={t("SELECT_FOLDER")}
                    className='presetText'
                    w='22.75rem'
                    ml={'1.5rem'}
                />
            )}

            <TextInput
                value={presetName}
                onChange={(event) => setPresetName(event.currentTarget.value)}
                ta={'left'}
                placeholder={t("ENTER_PRESET_NAME")}
                onKeyPress={handleKeyPress}
                mt='lg'
                w='22.75rem'
                className='presetText'
                ml={'1.5rem'}
                
            />
            <Flex justify="center" align="center" mt="xl" mb={"2.2rem"}>
                <CustomButton variant='cancel' onClick={onClose}>
                    {t("CANCEL")}
                </CustomButton>
                <CustomButton variant='submit' onClick={handleSave}>
                    {t("ADD")}
                </CustomButton>
            </Flex>
        </Modal>
    );
};

export default SavePresetModal;
