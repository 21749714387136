import { IconAlertTriangle, IconExclamationCircle } from "@tabler/icons-react";
import { Flex, Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface ErrorMessageProps {
  errorCode?: number | string;
  errorMessage?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorCode, errorMessage }) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  let errorMessageKey = 'ERROR_DEFAULT';
  const supportedErrors: any = [400, 401, 403, 404, 500];
  if(supportedErrors.includes(errorCode)) {
    errorMessageKey = `ERROR_${errorCode}`;
  } 
  
  return (
    <Flex align={"center"} justify={"center"} mt={"5rem"}>
      <IconExclamationCircle
        style={{ marginRight: theme.spacing.sm, color: theme.colors.dark[9] }}
      />
      <Text c={theme.colors.dark[9]} size="lg">{t(errorMessageKey) || errorMessage}</Text>
    </Flex>
  );
};

export default ErrorMessage;
