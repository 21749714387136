import { useEffect, RefObject } from 'react';
import { GridApi } from 'ag-grid-community';

export const useDeselectAllOnRowUnselect = (
    gridApiRef: RefObject<GridApi | null>,
    isRowSelected: boolean
) => {
    useEffect(() => {
        if (!gridApiRef.current) return;

        if (isRowSelected === false) {
            gridApiRef.current.deselectAll();
        }
    }, [isRowSelected, gridApiRef]);
};
