export class ConstantUtils {

    public static readonly successTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_SUCCESS"
    ];
    
    public static readonly refreshTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_REFRESH",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_REFRESH"
    ];
    
    public static readonly failTypes = [
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_FAIL",
        "FUND_MESSAGE_RESPONSE_TYPE_ORDER_UNCLAIM_FAIL"
    ];

    public static readonly orderClaimStates = {
        claim: "ORDER_CLAIM",
        unclaim: "ORDER_UNCLAIM"
    };

}
