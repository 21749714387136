import { Card, Flex, Tooltip, Text, Box, Center } from "@mantine/core";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../Common/Skeleton";
import { useAppSelector } from "../../store/hooks";
import { useMantineTheme } from "@mantine/core";

type Props = {
	param: string;
	TITLE: string;
};

const AllCountersCard = ({ param, TITLE }: Props) => {
	const { t } = useTranslation();
	const { counters, loading, error } = useAppSelector(state => state.market);
	const key = param.includes("future") ? "market_on_close_plus_days_counters" : param;
	const counts = counters ? (counters as any)[key] : null;
	const theme = useMantineTheme();

	const normalize = (value: number, total: number) => {
		return (value / total) * 95;
	};

	const renderStackedBars = (bar1: number, bar2: number, bar3: number, labels: string[]) => {
		let total = bar1 + bar2 + bar3;
		let len1 = bar1 > 25 ? 150 : bar1 * 6;
		let len2 = bar2 > 25 ? 150 : bar2 * 6;
		let len3 = bar3 > 25 ? 150 : bar3 * 6;
		if (total > 31) {
			if (bar1 >= bar2 && bar1 >= bar3) {
				len1 = 95;
				len2 = normalize(bar2, bar2 + bar3);
				len3 = normalize(bar3, bar2 + bar3);
			} else if (bar2 >= bar1 && bar2 >= bar3) {
				len2 = 95;
				len1 = normalize(bar1, bar1 + bar3);
				len3 = normalize(bar3, bar1 + bar3);
			} else {
				len3 = 95;
				len1 = normalize(bar1, bar1 + bar2);
				len2 = normalize(bar2, bar1 + bar2);
			}
		}

		return (
			<Flex>
				{bar1 > 0 && (
					<Tooltip
						label={labels[0]}
						c='#000'
						bg='#fff'
						style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
					>
						<div style={{ width: len1 }} className='box lightPurple'>
							{bar1 > 25 ? `... ${bar1} ...` : bar1}
						</div>
					</Tooltip>
				)}
				{bar2 > 0 && (
					<Tooltip
						label={labels[1]}
						c='#000'
						bg='#fff'
						style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
					>
						<div style={{ width: len2 }} className='box midPurple'>
							{bar2 > 25 ? `... ${bar2} ...` : bar2}
						</div>
					</Tooltip>
				)}
				{bar3 > 0 && (
					<Tooltip
						label={labels[2]}
						c='#000'
						bg='#fff'
						style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}
					>
						<div style={{ width: len3 }} className='box purple'>
							{bar3 > 25 ? `... ${bar3} ...` : bar3}
						</div>
					</Tooltip>
				)}
			</Flex>
		);
	};

	if (!counts) {
		return <div> <SkeletonLoader /></div>;
	  }

	if (loading ) {
		return <SkeletonLoader />;
	} else if (counts.total_funds_in_aggregate_count === 0 && param === "market_on_open_counters") return null;
	return (
		<Card key={param} withBorder shadow='sm' h='10rem' w='17rem' px='0'>
			{error && <Center c='red'>{error}</Center>}
			<Flex ta="center" justify="center" pb="0.8rem"> 

			<Text ta='center'  mt='-0.1rem' size="0.688rem" fw="590" color={theme.colors.grey[8]} mr={"0.2rem"}>
				{`${t(TITLE)}:`}
			</Text>
			<Text ta='center'  mt='-0.25rem' size="0.875rem" fw="590" color={theme.colors.grey[8]} mr={"0.2rem"}>
				{`${counts.total_funds_in_aggregate_count}`}
			</Text>
			<Text ta='center' mt='-0.1rem' size="0.688rem" fw="590" color={theme.colors.grey[8]}>
				{`funds`}
			</Text>
			</Flex>
			<Flex h='2.5rem' align='center' ml={'0.5rem'}>
				<Text className='bar-label'>{t("READY")}</Text>
				{renderStackedBars(
					counts.ready_state_counts.in_with_orders,
					counts.ready_state_counts.out_with_orders,
					counts.ready_state_counts.out_with_no_orders,
					["In with orders", "Out with orders", "Out without orders"],
				)}
			</Flex>
			<Flex h='2.5rem' align='center' ml={'0.5rem'}>
				<Box>
					<Text className='bar-label'>{t("NOT")}</Text>
					<Text className='bar-label'>{t("READY")}</Text>
				</Box>

				{renderStackedBars(counts.not_ready_state_counts.data_missing, counts.not_ready_state_counts.benchmark_not_updated, counts.not_ready_state_counts.start_of_day_recon_not_done, [
					"data missing",
					"benchmark not updated",
					"start of day recon not done"
				])}
			</Flex>
			<Flex h='2.5rem' align='center' ml={'0.5rem'}>
				<Text className='bar-label'>{t("COMPLETED")}</Text>

				{renderStackedBars(
					counts.completed_state_counts.in_with_no_order,
					counts.completed_state_counts.in_after_placing_order,
					counts.completed_state_counts.out_approved_with_overrides,
					["In with no order", "In after placing orders", "Out approved with overrides"],
				)}
			</Flex>
		</Card>
	);
};

export default AllCountersCard;
