import { isValid, isToday, isYesterday, isTomorrow, format, subDays, addDays } from "date-fns";

export const formatDate = (date: Date | null) => {
    if (date && isValid(date)) {
        if (isToday(date)) return "DATE_SPEC_TODAY";
        if (isYesterday(date)) return "DATE_SPEC_YESTERDAY";
        if (isTomorrow(date)) return "DATE_SPEC_TOMORROW";
        return format(date, "yyyy-MM-dd");
    }
    return "";
};

  export const getDateFromSpecifier = (specifier: string | Date | null) => {
    if (specifier === null) {
        return null;
    }

    const today = new Date();
    switch (specifier) {
        case 'DATE_SPEC_TODAY':
            return format(today, 'yyyy-MM-dd');
        case 'DATE_SPEC_YESTERDAY':
            return format(subDays(today, 1), 'yyyy-MM-dd');
        case 'DATE_SPEC_TOMORROW':
            return format(addDays(today, 1), 'yyyy-MM-dd');
        default:
            return typeof specifier === 'string' ? specifier : format(specifier, 'yyyy-MM-dd');
    }
};