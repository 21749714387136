import React from 'react';
import { Button, useMantineTheme } from '@mantine/core';
import { ButtonProps } from '../../../types';
import { t } from 'i18next';
import './CustomButton.css';

interface CustomButtonProps extends ButtonProps {
  variant:
    | 'cancel'
    | 'create'
    | 'submit'
    | 'no-issue'
    | 'fixed-issue'
    | 'update'
    | 'discard'
    | 'create-workitems'
    | 'discard-recon';
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  variant,
  disabled = false,
}) => {
  const theme = useMantineTheme();

  const getButtonStyles = (variant: CustomButtonProps['variant']) => {
    switch (variant) {
      case 'cancel':
        return {
          width: '6.313rem',
          height: '1.375rem',
          backgroundColor: 'transparent',
          color: theme.colors.dark[9],
          textDecoration: 'underline',
          marginRight: theme.spacing.lg,
          fontSize: '0.8125rem',
        };
      case 'create':
        return {
          width: '11.25rem',
          height: '1.5rem',
          backgroundColor: theme.colors.violet[8],
          color: 'white',
          fontWeight: 600,
          fontSize: '0.8125rem',
        };
      case 'submit':
        return {
          width: '6.313rem',
          height: '1.375rem',
          backgroundColor: theme.colors.yellow[0],
          color: theme.colors.dark[9],
          border: `1px solid ${theme.colors.grey[0]}`,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          fontSize: '0.8125rem',
        };
      case 'no-issue':
        return {
          width: '6.8125rem',
          height: '1.875rem',
          backgroundColor: 'white',
          color: theme.colors.grey[8],
          fontWeight: 600,
          fontSize: '0.8125rem',
          border: '1px solid lightgrey',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        };
      case 'fixed-issue':
        return {
          width: '6.8125rem',
          height: '1.875rem',
          backgroundColor: theme.colors.violet[8],
          color: 'white',
          fontWeight: 600,
          fontSize: '0.8125rem',
          marginTop: theme.spacing.sm,
        };
      case 'update':
        return { backgroundColor: theme.colors.violet[8] };
      case 'discard':
        return { backgroundColor: theme.colors.yellow[0] };
      case 'create-workitems':
        return { backgroundColor: theme.colors.violet[8] };
      case 'discard-recon':
        return { backgroundColor: theme.colors.yellow[0] };
      default:
        return {};
    }
  };

  const getClassName = (variant: CustomButtonProps['variant']) => {
    switch (variant) {
      case 'update':
        return 'update-button';
      case 'discard':
        return 'discard-button';
      case 'create-workitems':
        return 'create-workitem-button';
      case 'discard-recon':
        return 'discard-recon-button';
      default:
        return '';
    }
  };

  return (
    <Button
      onClick={disabled ? undefined : onClick} 
      style={getButtonStyles(variant)}
      className={getClassName(variant)}
      disabled={disabled} 
    >
      {t(children)}
    </Button>
  );
};

export default CustomButton;