import React from 'react';
import PageTitle from '../components/Common/PageTitle';
import ComingSoon from '../components/Common/ComingSoon/ComingSoon';

const CoacManagement: React.FC = () => {
  return <div>
    <PageTitle TITLE='COAC_MANAGEMENT'/>
    <ComingSoon />
  </div>;
};

export default CoacManagement;
