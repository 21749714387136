
import { Box, useMantineTheme } from "@mantine/core";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Attributes from "./Attributes";
import TargetsTolerances from "./TargetsTolerances";
import TOLTemplates from "./TOLTemplates";
import PageTitle from "../../../components/Common/PageTitle";
import SegmentedControl from "../../AlgoJournal/SegmentedControl";


const ConfigTabs: React.FC = () => {
    const { t } = useTranslation();
    const { fund_id, target_mode } = useParams();
    const theme = useMantineTheme();
    const [activeTab, setActiveTab] = useState<string>('attributes');

    const tabs = [
        {
            label: t('ATTRIBUTES'),
            value: 'attributes',
            content: <Attributes />
        },
        {
            label: t('TARGETS_TOLERANCE'),
            value: 'targets',
            content: <TargetsTolerances />
        },
        {
            label: t('TOL_TEMPLATES'),
            value: 'toltemplates',
            content: <TOLTemplates />
        },
    ];

    const tabRefs = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));

    const controlRef = useRef<HTMLDivElement>(null);

    const handleTabChange = (value: string) => {
        setActiveTab(value);
    };

    useEffect(() => {
        const activeIndex = tabs.findIndex(tab => tab.value === activeTab);
        const activeSegmentRef = tabRefs.current[activeIndex]?.current;
        const { offsetWidth, offsetLeft } = activeSegmentRef || {};
        const { style } = controlRef.current || {};
        console.log("Active tab: ", activeTab)

        if (style && offsetWidth !== undefined && offsetLeft !== undefined) {
            style.setProperty("--highlight-width", `${offsetWidth}px`);
            style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
        }
    }, [activeTab, tabs]);

    return (
        <>
            <Box p={'lg'}>
                <SegmentedControl
                    name="fund-tabs"
                    callback={handleTabChange}
                    controlRef={controlRef}
                    defaultIndex={0}
                    segments={tabs.map((tab, index) => ({
                        label: tab.label,
                        value: tab.value,
                        ref: tabRefs.current[index]
                    }))}
                />
            </Box>
            <Box ta="start">
                {tabs.find(tab => tab.value === activeTab)?.content}
            </Box>

        </>
    );
};

export default ConfigTabs;
