import React from 'react';
import { ActionIcon, useMantineTheme } from '@mantine/core';
import IconWrapper from '../../Common/IconWrapper';

interface ActionIconProps {
  onClick: () => void;
}

const CustomActionIcon: React.FC<ActionIconProps> = ({ onClick }) => {
  const theme = useMantineTheme(); 

  return (
    <ActionIcon
      color={theme.colors.grey[8]}
      onClick={onClick}
      variant='transparent'
      w="10px"
      h="16px"
      mb="sm"
      mt="sm"
    >
      <IconWrapper name="allfundtab" />
    </ActionIcon>
  );
};

export default CustomActionIcon;
