import React from 'react';
import ComingSoon from '../../components/Common/ComingSoon/ComingSoon';

const TableConfig: React.FC = () => {
    return <div>
    <ComingSoon />
  </div>;
};

export default TableConfig;
