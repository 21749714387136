import { useState, useEffect, useRef } from "react";
import {
    useMantineTheme,
    Text,
    Box,
    Flex,
    Collapse,
    ActionIcon,
    Divider,
    ScrollArea,
    Card,
} from "@mantine/core";
import AllCountersCard from "../../components/Dashboard/AllCountersCard";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { ActionItemsRow, DashData, ProcessedData } from "../../types";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import PageTitle from "../../components/Common/PageTitle";
import axios from "axios";
import { services } from "../../services";
import { useDispatch } from "react-redux";
import { setWorkItemsLength } from "../../store/userSlice";
import './Dashboard.css'
import { t } from "i18next";
import SkeletonLoader from "../../components/Common/Skeleton";
import { formatDueDate, getCurrentUTCDate } from "../../utils/dateUtils";
import { useAppSelector } from "../../store/hooks";
import CustomButton from "../../components/Common/Buttons/CustomButton";
import CollapsibleSectionGroup from "../../components/Dashboard/CollapsibleSectionGroup";

const Dashboard = () => {
    const theme = useMantineTheme();
    const [opened, { toggle }] = useDisclosure(false);
    const [selectedRows, setSelectedRows] = useState<ActionItemsRow[]>([]);
    const [workItems, setWorkItems] = useState<any[]>([]);
    const [workItemsTomorrow, setWorkItemsTomorrow] = useState<any[]>([]);
    const [workItemsLater, setWorkItemsLater] = useState<any[]>([]);
    const { user } = useAppSelector(state => state.user);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setError] = useState<string | null>(null);
    const [workType, setWorkType] = useState<string | null>(null);

    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [selectedRowCountTomorrow, setSelectedRowCountTomorrow] = useState(0);
    const [selectedRowCountLater, setSelectedRowCountLater] = useState(0);
    const isDisabled = workItems.length === 0 && workItemsTomorrow.length === 0 && workItemsLater.length === 0;

    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

    const [selectedComments, setSelectedComments] = useState<any[]>([]);

    const handleCommentsChange = (comments: any[]) => {
        setSelectedComments(comments);
    };

    const workTypeDisable = selectedRows.some(
        (row) => row.work_type === t("ALERT_CATEGORY_SOD_FUND_RECON_AGG_VIEW")
    );
    
    const handleRowSelection = (selection: ActionItemsRow[]) => {
        setSelectedRows(selection);
        if (selection.length > 0 && !opened) toggle();
        if (selection.length === 0 && opened) toggle();
    };
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const response = await axios.get(services.WORK_ITEM);
            setLoading(false);

            if (response.data) {
                const { today, tomorrow, later } = response.data.data;

                setWorkItems(processData(today));
                setWorkItemsTomorrow(processData(tomorrow));
                setWorkItemsLater(processData(later));
                setError(null);

                const targetStatuses = ['WORK_ITEM_STATUS_ACTION_NEEDED', 'WORK_ITEM_STATUS_CLOSED_PENDING'];
                const filteredTodayItems = today.filter(
                    (item: { work_item_status: string }) => targetStatuses.includes(item.work_item_status)
                );
                dispatch(setWorkItemsLength(filteredTodayItems.length));
            }
            if (!response.data) {
                setLoading(false);
                setError("API returned an error status: " + response.data.api_status);
            }
        } catch (error: any) {
            setLoading(false);
            const status = error.response?.status;
            const errorMessage = status
                ? `Error ${status}: ${error.response.statusText}`
                : "An unexpected error occurred.";
            dispatch(setWorkItemsLength(0));
            setError(errorMessage);
            console.error("Error fetching data:", errorMessage);
        }
    };

    const processData = (data: DashData[]): ProcessedData[] => {
        const formatComments = (comments?: { comment: string }[]): string => {
            if (!comments || !Array.isArray(comments)) {
                return '';
            }
            return comments
                .slice()
                .reverse()
                .map((c) => c.comment)
                .join(', ');
        };

        return data.map((dash) => {
            const processedData: ProcessedData = {
                id: dash.id || '-',
                due_time: dash.due_date.due_time || '-',
                work_type: t(dash.alert.category) || '-',
                status: t(dash.work_item_status) || '-',
                copilot_status: t("WAITING_FOR_ACTION") || '-',
                fund_id: dash.alert.alert_detail?.fund_id || "-",
                fund_name: dash.alert.alert_detail?.fund_name || "-",
                fund_group: dash.alert.alert_detail?.sod_fund_recon_aggregated_alert_detail?.fund_group || "-",
                description: dash.alert.description || '-',
                primary_fm_name: user?.fullName || user?.username || '-',
                comment: formatComments(dash.comments) || '-',
                creation_time: dash.alert.creation_time || '-',
                completed_time: dash.comments?.[0]?.completed_time || "-",
                completed_by: dash.comments?.[0]?.completed_by || "-",
                comments: dash.comments || [] || '-',
                due_date: dash.due_date ? formatDueDate(dash.due_date.due_date) : '-'
            };

            setWorkType(dash.alert.category);

            return processedData;
        });
    };

    const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const isFirstCallRef = useRef(true);
    useEffect(() => {
        if (!opened) {
            if (isFirstCallRef.current) {
                fetchData();
                isFirstCallRef.current = false;
            }
            intervalIdRef.current = setInterval(fetchData, 6000);
        } else if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
            isFirstCallRef.current = true;
        }

        return () => {
            if (intervalIdRef.current) clearInterval(intervalIdRef.current);
        };
    }, [opened]);

    const [comment, setComment] = useState('');

    const handleKeyDown = async (event: { key: string; preventDefault: () => void; }) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            try {
                const flatten = selectedComments.flat();
                const newComment = {
                    comment,
                    creation_time: getCurrentUTCDate(),
                    completed_time: getCurrentUTCDate(),
                    completed_by: user?.fullName || user?.username
                };
                console.log("flatten", flatten)
                const filteredFlatten = (flatten || []).filter(comment => comment !== null && comment !== undefined);
                const payload = {
                    comments: [...filteredFlatten, newComment]
                };

                const postResponse = await axios.post(`${services.WORK_ITEM}/${selectedRowIds}`, payload);

                if (postResponse.status === 200) {
                    setComment('');
                    fetchData();
                    toggle();
                } else {
                    console.error('Failed to post comment');
                }
            } catch (error) {
                console.error('Error fetching or posting comment:', error);
            }
        }
    };

    const onIssueClick = async (urlSuffix: string) => {
        const flatten = selectedComments.flat();

        const newComment = {
            comment,
            creation_time: getCurrentUTCDate(),
            completed_time: getCurrentUTCDate(),
            completed_by: user?.fullName || user?.username
        };
        const filteredFlatten = (flatten || []).filter(comment => comment !== null && comment !== undefined);
        const payload = {
            comments: [...filteredFlatten, newComment],
            id: String(selectedRowIds)
        };


        try {
            const response = await axios.post(`${services.WORK_ITEM}/${urlSuffix}`, [payload]);

            if (response.status === 200) {
                setComment('');
                fetchData();
                toggle();
                setError(null)
            } else {
                console.error('Failed to post comment');
            }
        } catch (error: any) {
            setError(error.response.status);
        }
    };

    const handleSelectionChange = (selectedIds: string[]) => {
        setSelectedRowIds(selectedIds);
    };

    return (
        <>
            <PageTitle TITLE="My Dashboard" />
            <div className="dashboard-top">
                <ScrollArea type="auto">
                    <Flex justify="center" className="counters-grid" mb="xxs">
                        <Box mr="1.875rem">
                            <Link to={routes.MARKET_ON_OPEN}>
                                <AllCountersCard param="market_on_open_counters" TITLE="MARKET_ON_OPEN" />
                            </Link>
                        </Box>
                        <Box mr="1.875rem">
                            <Link to={routes.MARKET}>
                                <AllCountersCard param="market_counters" TITLE="Market" />
                            </Link>
                        </Box>
                        <Box mr="1.875rem">
                            <Link to={routes.MARKET_ON_CLOSE}>
                                <AllCountersCard param="market_on_close_counters" TITLE="MARKET_ON_CLOSE" />
                            </Link>
                        </Box>
                        <Box>
                            <Link to={routes.MARKET_FUTURE_DAYS}>
                                <AllCountersCard param="market_on_close_future_days" TITLE="MARKET_ON_CLOSE_PLUS_DAYS" />
                            </Link>
                        </Box>
                    </Flex>
                </ScrollArea>

                <div className="tables">
                    <Flex>
                        <Box flex={1} h="100%">
                            {loading ? (
                                <div><SkeletonLoader /></div>
                            ) : (
                                <CollapsibleSectionGroup
                                    sections={[
                                        {
                                            title: t('TODAY'),
                                            count: workItems.length,
                                            rowData: workItems,
                                            updateSelectedRowCount: (count) => { setSelectedRowCount(count); },
                                        },
                                        {
                                            title: t('TOMORROW'),
                                            count: workItemsTomorrow.length,
                                            rowData: workItemsTomorrow,
                                            updateSelectedRowCount: (count) => { setSelectedRowCountTomorrow(count); },
                                        },
                                        {
                                            title: t('LATER'),
                                            count: workItemsLater.length,
                                            rowData: workItemsLater,
                                            updateSelectedRowCount: (count) => { setSelectedRowCountLater(count); },
                                        },
                                    ]}
                                    handleRowSelection={handleRowSelection}
                                    handleSelectionChange={handleSelectionChange}
                                    handleCommentsChange={handleCommentsChange}
                                />
                            )}
                        </Box>

                        <Box flex="none" mt="1.5rem" h="65vh">
                            <Box className={`border-around-light ${opened ? '' : 'collapsed'}`}>
                                <Flex justify="flex-end">
                                    <ActionIcon
                                        variant="transparent"
                                        color={theme.colors.grey[8]}
                                        aria-label="Action Form"
                                        onClick={toggle}
                                        w="10px"
                                        h="16px"
                                        me="-0.75rem"
                                        mb="sm"
                                        disabled={isDisabled}
                                    >
                                        {opened ? <IconChevronRight /> : <IconChevronLeft />}
                                    </ActionIcon>
                                </Flex>
                                <Collapse in={opened}>
                                    <Flex direction="column" align="center" p="-5rem">
                                        <Text c={theme.colors.grey[8]} fw={600} size="0.8rem">
                                            {selectedRowCount === 0 ? t("SELECT_FUND_ACTION") : `${t("TOTAL")} ${selectedRowCount} ${t("FUNDS_SELECTED")}`}
                                        </Text>
                                        <Card bg={theme.colors.grey[1]} h="7.5rem" w="10.375rem" mt="md" mb="md">
                                            <input
                                                style={{
                                                    backgroundColor: theme.colors.grey[1],
                                                    border: 'none',
                                                    height: '8rem',
                                                    textAlign: 'left',
                                                    paddingBottom: '5rem',
                                                }}
                                                className="input-border"
                                                placeholder={t("COMMENT")}
                                                onKeyDown={handleKeyDown}
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            />
                                        </Card>
                                        <CustomButton
                                            variant="no-issue"
                                            onClick={() => onIssueClick('no-issue')}
                                            disabled={workTypeDisable}
                                        >
                                            {t("NO_ISSUE")}
                                        </CustomButton>

                                        <CustomButton
                                            variant="fixed-issue"
                                            onClick={() => onIssueClick('fixed-issue')}
                                            disabled={workTypeDisable}
                                        >
                                            {t("FIXED_ISSUE")}
                                        </CustomButton>

                                        <Divider w="7.5rem" h="0.5rem" mt="xxl" />
                                    </Flex>
                                </Collapse>
                            </Box>
                        </Box>
                    </Flex>
                </div>
            </div>
        </>
    );
};

export default Dashboard;