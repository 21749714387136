import { applyDecorators, getConfig } from "../../utils/conversions";

const systemConfig = getConfig("position_summary");

const valueInPercentage = systemConfig?.UNIT?.value === "percentage";
const dataConfig = {
	decorators: valueInPercentage ? systemConfig?.PERCENTAGE?.decorators : systemConfig?.CURRENCY?.decorators,
	multipleBy: valueInPercentage ? 100 : 1, //1 in case of default
	decimal: 2,
	tickCount: 10,
	xLabelLimit: 9,
	xTickBufferMin: 0.3,
	xTickBufferMax: 0.2,
	tickBufferMinMax: 0.5, // 50% buffer from other side of graph
}

const formatValues = (value: any) => {
	if(!value) return value;
	return parseFloat(applyDecorators((value * dataConfig.multipleBy), dataConfig.decorators));
};
const formatDeltaValue = (delta: number) : any => delta ? (delta * -1).toFixed(dataConfig.decimal) : 0;

const formatData = (data: any, deltaValues: number[]) => {
	return data
		.map((item: any, index:number) => ({
			...item,
			value: deltaValues[index] ? formatValues(0) : formatValues(item.value),
			changedValue: deltaValues[index] ? formatValues(item.value) : formatValues(0),
			delta: formatValues(item.delta),
			formattedDelta: formatDeltaValue(formatValues(item.delta)),
		}))
		.sort((a: any, b: any) => a.key.localeCompare(b.key));
}

const getMinAndMax = (data: any, min_limit: number, max_limit: number) => {
	min_limit = formatValues(min_limit);
	max_limit = formatValues(max_limit);

	const values = data.map((item: any) => (parseFloat(item.value) + parseFloat(item.changedValue)) - item.delta);
	let minX = Math.min(...values, min_limit);
	let maxX = Math.max(...values, max_limit);
	
	if(minX === 0 || minX > -(maxX * dataConfig.tickBufferMinMax)) {
		minX = -parseFloat(Math.abs(maxX * dataConfig.tickBufferMinMax).toFixed(dataConfig.decimal));
	} else if(maxX === 0 || Math.abs(minX * dataConfig.tickBufferMinMax) > maxX) {
		maxX = parseFloat(Math.abs(minX * dataConfig.tickBufferMinMax).toFixed(dataConfig.decimal));
	}

	const xTickBufferMin = minX * dataConfig.xTickBufferMin;
	const xTickBufferMax = maxX * dataConfig.xTickBufferMax;

	return {minX, maxX, xTickBufferMin, xTickBufferMax, min_limit, max_limit};
}


function tooltipFormatter(value: any, name: string, props: any) {
	const { formattedDelta } = props.payload;
	if((formattedDelta && name !== "changedValue") || (!formattedDelta && name !== "value")) return [null, null];
	if (formattedDelta) {
			const currentValue = parseFloat(value) + parseFloat(formattedDelta);
			return ["", `${applyDecorators(currentValue, dataConfig.decorators)} ${String.fromCharCode(0x2192)} ${applyDecorators(value, dataConfig.decorators)}`];
	} else {
			return ["", applyDecorators(value, dataConfig.decorators)];
	}
}

export {
	formatValues,
	formatDeltaValue,
	dataConfig,
	formatData,
	getMinAndMax,
	tooltipFormatter,
}