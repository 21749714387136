import './Leftnav.css';
import { Box, Button, Container, Flex, Paper, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconRefresh, IconSettings } from '@tabler/icons-react';
import { routes } from '../../routes';
import { useRef, useEffect } from 'react';
import IconWrapper from '../Common/IconWrapper';
import { handleLogout } from '../../utils/handleLogOut';

const AdminNavbar = () => {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const paperRef = useRef<HTMLDivElement>(null);

    const labelsWithIcons = [
        {
            icon: <IconWrapper name="usersAdmin" />,
            label: t("USERS"),
            link: routes.USERS
        },
        {
            icon: <IconWrapper name="funds" />,
            label: t("CAMELCASE_FUNDS"),
            link: routes.COMING
        },
        {
            icon: <IconWrapper name="preferences" />,
            label: t("PREFERENCES"),
            link: routes.COMINGPREFERENCE
        },
        {
            icon: <IconWrapper name="softwareUpdates" />,
            label: t("SOFTWARE_UPDATES"),
            link: routes.COMINGUPDATES
        },
        {
            icon: <IconWrapper name="support" />,
            label: t("CUSTOMER_SUPPORT"),
            link: routes.COMINGSUPPORT
        },
    ];

    useEffect(() => {
        // Check if the current pathname matches any of the routes
        const validPaths = labelsWithIcons.map(item => item.link);
        if (!validPaths.includes(pathname)) {
            // If the current pathname is not valid, navigate to the USERS route
            navigate(routes.USERS);
        }
    }, [pathname, navigate, labelsWithIcons]);

    return (
        <Container>
            <Paper shadow="sm" bg={theme.colors.grey[0]} className='paper' ref={paperRef}>
                <div className='header'>
                    <Text className='textupper' color={theme.colors.grey[5]}>
                        {t("Atlantis")}
                    </Text>
                </div>
                <Box className="inputnavbar">
                    <TextInput
                        width={180}
                        height={28}
                        placeholder={t("SEARCH")}
                        leftSection={<IconWrapper name="search" />}
                        radius="md"
                        size="xs"
                        variant="filled"
                    />
                </Box>
                {labelsWithIcons.map((item, idx) => (
                    <Link key={idx} to={item.link}>
                        <Flex
                            className={`usernameTer liststyle`}
                            ml={'0.47rem'}
                            style={{
                                background: item.link === pathname
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                            }}
                        >
                            {item.icon}
                            <Text
                                className='fundlisttext'
                                color={theme.colors.grey[5]}
                                ml="md"
                                mt={'-0.1rem'}
                            >
                                {item.label}
                            </Text>
                        </Flex>
                    </Link>
                ))}
                <Button bg={'transparent'} mt={'2.625rem'} ml={'-1.25rem'} onClick={handleLogout}>
                    <Flex
                        className={`usernameTer liststyle`}
                        align="flex-start"
                        justify="flex-start"
                    >
                        <IconWrapper name='sign-out' />
                        <Text
                            className='fundlisttext'
                            color={theme.colors.grey[5]}
                            ml="md"
                            mt={'-0.1rem'}
                        >
                            {t("SIGN_OUT")}
                        </Text>
                    </Flex>
                </Button>

            </Paper>
        </Container>
    );
};

export default AdminNavbar;