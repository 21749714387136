import { jwtDecode } from "jwt-decode";
import { ADMIN } from "../constants";

export const validateEmail = (input: string): boolean => {
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  return regEx.test(input);
};

export const validateUsername = (input: string): boolean => {
  const regEx = /^[A-Za-z][A-Za-z0-9_]{5,30}$/;
  return regEx.test(input);
};

export const validatePassword = (input: string): boolean => {
  const regex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;
  return regex.test(input);
};

export const isExpiredToken = (token: string): boolean => {
  const decoded_token = jwtDecode(token);
  return decoded_token?.exp
    ? decoded_token.exp - Math.floor(Date.now() / 1000) <= 0
    : false;
};

export const isAdmin = (roles: [string] | undefined): boolean => {
  if (!roles) return false
  else return roles.includes(ADMIN)
}