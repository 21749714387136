import { useTranslation } from "react-i18next";
import { Box, Divider, Flex, Text, useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconChevronLeft, IconDownload } from "@tabler/icons-react";
import { useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import Select from "./Select/Select";

type Props = {
    TITLE?: string;
    subtitle?: string;
    subtitle2?: string;
    subtitle3?: string;
    back?: boolean;
    full_report?: string;
    onMarketStateChange?: (marketState: string) => void;
};

const PageTitle = ({ TITLE, subtitle, subtitle2, subtitle3, full_report, back = false, onMarketStateChange }: Props) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { fund_id, target_date, target_mode, fund_state } = useParams();
    function formatTargetMode(target_mode: string) {
        return target_mode.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }

    // Formatted target_mode
    const formattedTargetMode = formatTargetMode(target_mode || "");
    const location = useLocation();
    const { pathname, search } = location;
    const queryParams = new URLSearchParams(search);
    const selected = queryParams.get("selected");

    const [selectedOption, setSelectedOption] = useState('MARKET_ON_OPEN');

    const handleDropdownChange = (value: string | null) => {
        if (value !== null) {
            setSelectedOption(value);
            // Update URL with new selected option
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.set('market_state', value);
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);

            handleMarketStateChange(value);
        }
    };

    const handleMarketStateChange = (marketState: string) => {
        if (onMarketStateChange) {
            onMarketStateChange(marketState);
        }
    };

    return (
        <header>
            <Flex align='center' justify='space-between' p='1rem'>
                <Flex align="center" w="100%">
                    {back && (
                        <IconChevronLeft
                            color={theme.colors.grey[5]}
                            onClick={() => navigate(-1)}
                            cursor="pointer"
                        />
                    )}

                    <Flex ml="1.25rem" flex="1">
                        <Text fw="700" size="1.25rem">
                            {TITLE && t(TITLE)}
                            {subtitle && (
                                <Text span ml="1.0625rem" c={theme.colors.grey[5]} fw="700" size="1rem">
                                    {t(subtitle)}
                                </Text>
                            )}
                        </Text>

                        {subtitle2 && (
                            <Flex mt="xxs">
                                <span className="vertical-line"></span>
                                <Text span c={theme.colors.grey[5]} fw="700" size="1rem">
                                    {t(subtitle2)}
                                </Text>
                            </Flex>
                        )}

                        {subtitle3 && (
                            <Flex mt="xxs">
                                <span className="vertical-line"></span>
                                <Text span c={theme.colors.grey[5]} fw="700" size="1rem">
                                    {t(subtitle3)}
                                </Text>
                            </Flex>
                        )}
                    </Flex>

                    {full_report && (
                        <Flex ml="auto" align="center">
                            <IconDownload height={17} width={17} color={theme.colors.grey[8]} />
                            <Text ml="0.4rem" size="md" c={theme.colors.grey[8]} fw={600}>{t(full_report)}</Text>
                        </Flex>
                    )}

                    {TITLE === fund_id && (
                        <Flex ml="0.9375rem">
                            <Text pr="0.6875rem" fw="520" size="0.9375rem" c={theme.colors.grey[5]}>
                                {formattedTargetMode}
                            </Text>
                            <Box h="md" w="1px" bg={theme.colors.gray[4]} mr="0.625rem" />
                            <Text pr="0.625rem" fw="520" size="0.9375rem" c={theme.colors.grey[5]}>
                                {fund_state}
                            </Text>
                            <Box h="md" w="1px" bg={theme.colors.gray[4]} mr="0.625rem" />
                            <Text pr="sm" fw="520" size="0.9375rem" c={theme.colors.grey[5]}>
                                {target_date}
                            </Text>
                        </Flex>
                    )}
                </Flex>

                {TITLE === "All Fund Status" && (
                    <Flex>
                        <Box ml='1rem' miw={"9.375rem"}>
                            <Select
                                data={[
                                    { label: "Market on Open", value: "MARKET_ON_OPEN" },
                                    { label: "Market on Close", value: "MARKET_ON_CLOSE" }
                                ]}
                                defaultValue={selectedOption}
                                onChange={handleDropdownChange}
                                allowDeselect={false}
                                className="page-dropdown"
                            />
                        </Box>
                    </Flex>
                )}
            </Flex>
            <Divider />
        </header>
    );
};

export default PageTitle;

