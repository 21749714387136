type PermissionEntry = {
    fundGroup: string;
    permissions: string[];
  };
  
  export const hasWritePermission = (fundGroup: string, permissions: string): boolean => {
    const permissionJwtsString = localStorage.getItem('fundGroup');
    const permissionJwts: PermissionEntry[] = permissionJwtsString ? JSON.parse(permissionJwtsString) : [];
    const matchingPermission = permissionJwts.find((entry) => entry.fundGroup === fundGroup);
    return !!matchingPermission && matchingPermission.permissions.includes(permissions);
  };