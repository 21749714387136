import React, { useEffect, useRef } from "react";
import { Container, Text, Paper, useMantineTheme, TextInput, Badge, Box } from "@mantine/core";
import { Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import "@mantine/core/styles.css";
import "./Leftnav.css";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchFundManagerCounters, updateState } from "../../store/marketSlice";
import { useInterval } from "../../utils/hooks/useInterval";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import IconWrapper from '../Common/IconWrapper';
import { IconSettings } from "@tabler/icons-react";

const READY = "Ready";
const NOTREADY = "Not Ready";
const COMPLETED = "Completed";
const DASHBOARD = "Dashboard";
const ALLFUNDSTATUS = "All-Fund-Status";
const COACMANAGEMENT = "Coac Management";
const ALLFUND = "All Fund Query";

const LeftNavbar: React.FC = () => {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const selected = searchParams.get("selected") || READY;
    const { user } = useAppSelector(state => state.user);
    const { counters } = useAppSelector(state => state.market)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const workItemsLength = useSelector((state: RootState) => state.user.workItemsLength);
    const paperRef = useRef<HTMLDivElement>(null);

    const counts = {
        marketOnOpenCount:
            counters?.market_on_open_counters?.total_funds_in_aggregate_count || 0,
        marketOnOpenCountReady:
            counters?.market_on_open_counters?.ready_state_counts?.total_funds || 0,
        marketOnOpenCountNotReady:
            counters?.market_on_open_counters?.not_ready_state_counts?.total_funds || 0,
        marketOnOpenCountCompleted:
            counters?.market_on_open_counters?.completed_state_counts?.total_funds || 0,
        marketCount:
            counters?.market_counters.total_funds_in_aggregate_count || 0,
        marketCountReady:
            counters?.market_counters?.ready_state_counts?.total_funds || 0,
        marketCountNotReady:
            counters?.market_counters?.not_ready_state_counts?.total_funds || 0,
        marketCountCompleted:
            counters?.market_counters?.completed_state_counts?.total_funds || 0,
        marketOnCloseCount:
            counters?.market_on_close_counters.total_funds_in_aggregate_count || 0,
        marketOnCloseCountReady:
            counters?.market_on_close_counters?.ready_state_counts?.total_funds || 0,
        marketOnCloseCountNotReady:
            counters?.market_on_close_counters?.not_ready_state_counts?.total_funds || 0,
        marketOnCloseCountCompleted:
            counters?.market_on_close_counters?.completed_state_counts?.total_funds || 0,
        marketOnClosePlusDaysCount:
            counters?.market_on_close_plus_days_counters.total_funds_in_aggregate_count || 0,
        marketOnClosePlusDaysCountReady:
            counters?.market_on_close_plus_days_counters?.ready_state_counts?.total_funds || 0,
        marketOnClosePlusDaysCountNotReady:
            counters?.market_on_close_plus_days_counters?.not_ready_state_counts?.total_funds || 0,
        marketOnClosePlusDaysCountCompleted:
            counters?.market_on_close_plus_days_counters?.completed_state_counts?.total_funds || 0,
    }

    const renderLabels = (labelsArray: any[], path: string) => {
        const isCurrentPath = path === pathname;
        return labelsArray.map(({ id, label, count, icon }) => (
            <div className="renderstyle" key={id}>
                <Link to={`${path}?selected=${id}`} className={"singlebutton"}>
                    <div
                        className={"renderselect"}
                        style={{
                            background:
                                isCurrentPath && selected === id
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                        }}
                    >
                        <div className={"totalPillContent"}>
                            {icon}
                            <Text className={"rownamestyle"}>{label}</Text>
                            <Badge
                                circle
                                className={"rightAlign"}
                                size='md'
                                bg={theme.colors.yellow[0]}
                                c={theme.colors.violet[8]}
                            >
                                <Text fw={700} size="0.7rem">{count > 0 ? count : '0'}</Text>
                            </Badge>
                        </div>
                    </div>
                </Link>
            </div>
        ));
    };


    const dashboardLabel = t("MY_DASHBOARD");
    const allfundstatusLabel = t("ALL_FUND_STATUS");
    const coac_management = t("COAC_MANAGEMENT");


    const dashboard = (labelsArray: any[], path: string) => {
        const isCurrentPath = path === pathname;
        return labelsArray.map(({ id }) => (
            <div className="labelgapdash">
                <Link key={id} to={`${path}?selected=${id}`}>
                    <div
                        className={`${"username"} ${"dashstyle"}`}
                        style={{
                            background:
                                isCurrentPath && selected === id
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                        }}
                    >
                        <IconWrapper name="dashboard" className="dashboard" />
                        <Text
                            className={"fundlisttext"}
                            c={theme.colors.grey[5]}
                            onClick={() => navigate(routes.HOME)}
                        >
                            {dashboardLabel}
                        </Text>
                        {workItemsLength !== 0 && (
                            <Badge
                                circle
                                className={`${"dashboardpill"} ${"rightAlign"}`}
                                size={theme.fontSizes.xl}
                                bg={theme.colors.yellow[0]}
                                c={theme.colors.violet[8]}
                                mr={9}
                            >
                                <Text fw={700} size="0.7rem"> {workItemsLength}</Text>
                            </Badge>
                        )}

                    </div>
                </Link>
            </div>
        ));
    };


    const allfund = (labelsArray: any[], path: string) => {
        const isCurrentPath = path === pathname;
        return labelsArray.map(({ id }) => (
            <div className="labelgap">
                <Link key={id} to={`${path}?selected=${id}`}>
                    <div
                        className={`${"username"} ${"allfundstyle"} `}
                        style={{
                            background:
                                isCurrentPath && selected === id
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                        }}
                    >
                        <IconWrapper name="SmartSearchIcon" className="allfund" />
                        <Text className={"allfundtext"} c={theme.colors.grey[5]}>
                            {allfundquerylabel}
                        </Text>
                    </div>
                </Link>
            </div>
        ));
    };

    const coacmanagement = (labelsArray: any[], path: string) => {
        const isCurrentPath = path === pathname;
        return labelsArray.map(({ id }) => (
            <div className="labelgap">
                <Link key={id} to={`${path}?selected=${id}`}>
                    <div
                        className={`${"username"} ${"coacstyle"} `}
                        style={{
                            background:
                                isCurrentPath && selected === id
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                        }}
                    >
                        <IconWrapper name="coac" className="coac" />
                        <Text className={"allfundtext"} c={theme.colors.grey[5]}>
                            {coac_management}
                        </Text>
                    </div>
                </Link>
            </div>
        ));
    };

    const allfundstatus = (labelsArray: any[], path: string) => {
        const isCurrentPath = path === pathname;
        return labelsArray.map(({ id }) => (
            <div className="labelgapfundstatus">
                <Link key={id} to={`${path}?selected=${id}`}>
                    <div
                        className={`${"username"} ${"allfundstyle"}  ${allfundstatus} `}
                        style={{
                            background:
                                isCurrentPath && selected === id
                                    ? theme.colors.grey[9]
                                    : theme.colors.grey[0],
                        }}
                    >

                        <Text className={"allfundtext"} c={theme.colors.grey[5]} >
                            {allfundstatusLabel}
                        </Text>
                    </div>
                </Link>
            </div>
        ));
    };


    useEffect(() => {
        dispatch(updateState({ key: 'loading', value: true }));
        dispatch(fetchFundManagerCounters(user ? user.username : ''));
    }, [pathname, searchParams, user, dispatch]);

    useInterval({
        callback: () => dispatch(fetchFundManagerCounters(user ? user.username : '')),
        type: "thunk"
    });

    const readyLabel = t("READY");
    const notreadyLabel = t("NOT_READY");
    const completedLabel = t("COMPLETED");
    const atlantislabel = t("ATLANTIS");
    const allfundquerylabel = t("SMART_SEARCH");
    const marketonopen = t("MOO_ORDERS");
    const marketonclose = t("MOC_ORDERS");
    const marketlabel = t("MARKET_ORDERS");
    const marketfuturelabel = t("FUTURE_ORDERS");
    const readyicon = <div className={"iconStyle"}><IconWrapper name="readyicon" /></div>
    const notreadyicon = <div className={"iconStyle"}><IconWrapper name="notreadyicon" /></div>
    const completedicon = <div className={"iconStyle"}><IconWrapper name="completedicon" /></div>


    const labelsWithIcons = [
        {
            id: READY,
            label: readyLabel,
            count: counts.marketOnOpenCountReady,
            icon: readyicon,
        },
        {
            id: NOTREADY,
            label: notreadyLabel,
            count: counts.marketOnOpenCountNotReady,
            icon: notreadyicon,
        },
        {
            id: COMPLETED,
            label: completedLabel,
            count: counts.marketOnOpenCountCompleted,
            icon: completedicon,
        },
    ];

    const labelsWithIconsMarket = [
        {
            id: READY,
            label: readyLabel,
            count: counts.marketCountReady,
            icon: (
                readyicon
            ),
        },
        {
            id: NOTREADY,
            label: notreadyLabel,
            count: counts.marketCountNotReady,
            icon: notreadyicon,
        },
        {
            id: COMPLETED,
            label: completedLabel,
            count: counts.marketCountCompleted,
            icon: completedicon,
        },
    ];

    const labelsWithIconsMarketOnClose = [
        {
            id: READY,
            label: readyLabel,
            count: counts.marketOnCloseCountReady,
            icon: (
                readyicon
            ),
        },
        {
            id: NOTREADY,
            label: notreadyLabel,
            count: counts.marketOnCloseCountNotReady,
            icon: notreadyicon,
        },
        {
            id: COMPLETED,
            label: completedLabel,
            count: counts.marketOnCloseCountCompleted,
            icon: completedicon,
        },
    ];

    const labelsWithIconsMarketFutureDays = [
        {
            id: READY,
            label: readyLabel,
            count: counts.marketOnClosePlusDaysCountReady,
            icon: (
                readyicon
            ),
        },
        {
            id: NOTREADY,
            label: notreadyLabel,
            count: counts.marketOnClosePlusDaysCountNotReady,
            icon: notreadyicon,
        },
        {
            id: COMPLETED,
            label: completedLabel,
            count: counts.marketOnClosePlusDaysCountCompleted,
            icon: completedicon,
        },
    ];

    const labelsDashboard = [
        {
            id: DASHBOARD,
        },
    ];

    const labelsfundstatus = [
        {
            id: ALLFUNDSTATUS,
        },
    ];

    const labelsAllFund = [
        {
            id: ALLFUND,
        },
    ];

    const labelsCoacManagement = [
        {
            id: COACMANAGEMENT,
        },
    ];

    const defaultSelect = "?selected=Ready"

    return (
        <Container>
            <Paper shadow='sm' bg={theme.colors.grey[0]} className={"paper"} ref={paperRef}>
                <Box className={"header"}>
                    <IconWrapper name='Logo-long' className="nav-logo" />
                </Box>

                <div className="scroll-bar">
                    <div className={"inputnavbar"}>
                        <TextInput
                            w={180}
                            h={28}
                            placeholder={t("ASK_ANYTHING")}
                            leftSection={<IconWrapper name="AskAnythingIcon" />}
                            radius={"md"}
                            size={"xs"}
                            variant="filled"
                        />
                    </div>
                    <Link to={routes.ACCOUNT}>
                        <div className={"username"} >
                            <IconWrapper name="settingicon" className="userimg" />
                            <Text className={"settingtext"} c={theme.colors.grey[5]}>
                                {t("SETTINGS")}
                            </Text>
                        </div>
                    </Link>

                    {dashboard(labelsDashboard, routes.HOME)}
                    {allfund(labelsAllFund, routes.ALLFUNDQUERY)}
                    {coacmanagement(labelsCoacManagement, routes.COACMANAGEMENT)}

                    <Link to={routes.ALLFUNDQUERY}></Link>

                    {allfundstatus(labelsfundstatus, routes.ALLFUNDSTATUS)}

                    <div className={"tables-nav"}>
                        {counts.marketOnOpenCount > 0 && (
                            <>
                                <div className={"gap"}>
                                    <Link to={`${routes.MARKET_ON_OPEN}${defaultSelect}`} className={"linkNoUnderline"}>
                                        <div className={"flexContainer"}>
                                            <Text className={"navtext"} c={theme.colors.grey[5]}>
                                                {marketonopen}
                                            </Text>
                                            <Badge
                                                circle
                                                className={`${"totalPillmarketonclose"} ${"rightAlign"}`}
                                                size={theme.fontSizes.xl}
                                                bg={theme.colors.yellow[0]}
                                                c={theme.colors.violet[8]}
                                            >
                                                <Text fw={700} size="0.7rem">{counts.marketOnOpenCount}</Text>
                                            </Badge>
                                        </div>
                                    </Link>
                                </div>
                                <div>{renderLabels(labelsWithIcons, routes.MARKET_ON_OPEN)}</div>
                            </>
                        )}

                        <div className={"gap"}>
                            <Link to={`${routes.MARKET}${defaultSelect}`} className={"linkNoUnderline"}>
                                <div className={"flexContainer"}>
                                    <Text className={"navtext"} c={theme.colors.grey[5]}>
                                        {marketlabel}
                                    </Text>
                                    <Badge
                                        circle
                                        className={`${"totalPillmarketonclose"} ${"rightAlign"}`}
                                        size={theme.fontSizes.xl}
                                        bg={theme.colors.yellow[0]}
                                        c={theme.colors.violet[8]}
                                    >
                                        <Text fw={700} size="0.7rem">{counts.marketCount}</Text>
                                    </Badge>
                                </div>
                            </Link>
                        </div>

                        {renderLabels(labelsWithIconsMarket, routes.MARKET)}

                        <div className={"gap"}>
                            <Link to={`${routes.MARKET_ON_CLOSE}${defaultSelect}`} className={"linkNoUnderline"}>
                                <div className={"flexContainer"}>
                                    <Text className={"navtext"} c={theme.colors.grey[5]}>
                                        {marketonclose}
                                    </Text>
                                    <Badge
                                        circle
                                        className={`${"totalPillmarketonclose"} ${"rightAlign"}`}
                                        size={theme.fontSizes.xl}
                                        bg={theme.colors.yellow[0]}
                                        c={theme.colors.violet[8]}
                                    >
                                        <Text fw={700} size="0.7rem">{counts.marketOnCloseCount}</Text>
                                    </Badge>
                                </div>
                            </Link>
                        </div>

                        {renderLabels(labelsWithIconsMarketOnClose, routes.MARKET_ON_CLOSE)}

                        <div className={"gap"}>
                            <Link to={`${routes.MARKET_FUTURE_DAYS}${defaultSelect}`} className={"linkNoUnderline"}>
                                <div className={"flexContainer"}>
                                    <Text className={"navtext"} c={theme.colors.grey[5]}>
                                        {marketfuturelabel}
                                    </Text>

                                    <Badge
                                        circle
                                        className={`${"totalPillmarketfuture"} ${"rightAlign"}`}
                                        size={theme.fontSizes.xl}
                                        bg={theme.colors.yellow[0]}
                                        c={theme.colors.violet[8]}
                                    >
                                        <Text fw={700} size="0.7rem">{counts.marketOnClosePlusDaysCount}</Text>
                                    </Badge>
                                </div>
                            </Link>
                        </div>

                        {renderLabels(labelsWithIconsMarketFutureDays, routes.MARKET_FUTURE_DAYS)}
                    </div>
                </div>
            </Paper>
        </Container>
    );
};

export default LeftNavbar;