import { useState } from 'react';
import { Tabs, useMantineTheme } from '@mantine/core';
import { t } from 'i18next';
import EqutizePage from '../../components/CashExposure/EquitizePage';
import ForeCast from '../../components/CashExposure/ForeCast';
import styles from './CashTabs.module.css';

export function CashTabs() {
    const [activeTab, setActiveTab] = useState<string | null>('equitization');
    const theme = useMantineTheme();

    return (
        <Tabs value={activeTab} onChange={setActiveTab} mt="md">
            <Tabs.List>
                <Tabs.Tab
                    value="equitization"
                    color={theme.colors.violet[8]}
                    className={`${styles.tab} ${activeTab === 'equitization' ? styles.activeTab : ''}`}
                >
                    {t("EQUITIZATION")}
                </Tabs.Tab>
                <Tabs.Tab
                    value="forecast"
                    color={theme.colors.violet[8]}
                    className={`${styles.tab} ${activeTab === 'forecast' ? styles.activeTab : ''}`}
                >
                    {t("FORECAST")}
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="equitization"><EqutizePage /></Tabs.Panel>
            <Tabs.Panel value="forecast"><ForeCast /></Tabs.Panel>
        </Tabs>
    );
}
