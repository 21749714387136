import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MarketCounterSingle } from "../types";
import axios from "axios";
import { services } from "../services";
import { logoutReload } from "../constants/logoutReload";
import { getCurrentUTCDate } from "../utils/dateUtils";
import { currentDate } from "../constants";

const initialMarketCounters = {
  ready_state_counts: {
    total_funds: 0,
    in_with_orders: 0,
    out_with_orders: 0,
    out_with_no_orders: 0,
  },
  not_ready_state_counts: {
    total_funds: 0,
    data_missing: 0,
    benchmark_not_updated: 0,
    start_of_day_recon_not_done: 0, 
  },
  completed_state_counts: {
    total_funds: 0,
    in_with_no_order: 0,
    in_after_placing_order: 0,
    out_approved_with_overrides: 0,
  },
  total_funds_in_aggregate_count: 0,
};

interface MarketCounterFuture extends MarketCounterSingle {
  target_date: string;
}

interface MarketState {
  loading: boolean;
  error: string | null;
  counters: {
    market_on_open_counters: MarketCounterSingle;
    market_counters: MarketCounterSingle;
    market_on_close_counters: MarketCounterSingle;
    market_on_close_plus_days_counters: MarketCounterSingle;
    moc_future_days_counters: MarketCounterFuture[];
  };
  as_of_date: string | null; 
  target_date: string | null;
}

const initialState: MarketState = {
  loading: false,
  error: null,
  counters: {
    market_on_open_counters: initialMarketCounters,
    market_counters: initialMarketCounters,
    market_on_close_counters: initialMarketCounters,
    market_on_close_plus_days_counters: initialMarketCounters,
    moc_future_days_counters: [],  // Initialize as empty array for future days
  },
  as_of_date: null,
  target_date: "",  // Optional or dependent on further use cases
};

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    updateState: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    },
    resetState: () => initialState, 
  },
});

export const { updateState } = marketSlice.actions;

export const fetchFundManagerCounters = createAsyncThunk(
  "market/fetchFundManagerCounters",
  async (username: string, { dispatch }) => {
    try {
      const queryParams = {
        calc_date: getCurrentUTCDate(currentDate) || "",
        username,
      };
      const res = await axios.get(
        `${services.FUND_MANAGER_COUNTERS}?${new URLSearchParams(queryParams)}`
      );

      if (!res.data || !res.data.data) {
        dispatch(updateState({ key: "error", value: "No Data Found" }));
        dispatch(updateState({ key: "loading", value: false }));
        dispatch(marketSlice.actions.resetState());
        return;
      }

      // Update counters, as_of_date, and clear any existing error
      const { data } = res.data;
      dispatch(updateState({ key: "counters", value: data }));
      dispatch(updateState({ key: "as_of_date", value: data.as_of_date }));
      dispatch(updateState({ key: "error", value: null }));
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        const status = e.response.status;
        const detail = e.response.data?.detail;
        if (status === 403 && detail === "Token has expired") {
          console.error(detail);
          logoutReload();
        }
      }
      dispatch(updateState({ key: "error", value: "Error fetching data" }));
    } finally {
      dispatch(updateState({ key: "loading", value: false }));
    }
  }
);

export default marketSlice.reducer;
