import { Title, Text, Button, Container, Group, useMantineTheme } from "@mantine/core";
import classes from "./PageNotFound.module.css";
import { useNavigate } from "react-router-dom";

export function PageNotFound() {
	const theme = useMantineTheme();
	const navigate = useNavigate();

	return (
		<Container className={classes.root}>
			<div  className={classes.label} >404</div>
			<Title className={classes.title}>You have found a secret place.</Title>
			<Text c='dimmed' size='lg' ta='center' className={classes.description}>
				Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
				been moved to another URL.
			</Text>
			<Group justify='center'>
				<Button
					onClick={() => navigate(-1)}
					w={200}
					className={classes.loginbuttonstyle}
					bg={theme.colors.violet[8]}
					border-color={theme.colors.violet[8]}
				>
					Go Back
				</Button>
			</Group>
		</Container>
	);
}
