import React from 'react';
import { Modal, Text, TextInput, Grid, Flex, useMantineTheme } from '@mantine/core';
import { t } from 'i18next';
import { FundGroupModalUserProps } from '../../../types';
import CustomButton from '../../Common/Buttons/CustomButton';

const FundGroupModalUser: React.FC<FundGroupModalUserProps> = ({ opened, onClose, TITLE, buttonName }) => {
    const theme = useMantineTheme();
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            className='modal-fund-group'
            centered
            withCloseButton={false}
            radius={'30px'}
            size={"31rem"}
        >
            <Text ta={'center'} size='lg' fw={600} mt={"md"}>{t(TITLE)}</Text>

            <Grid mt={'xxxl'} align='center'>
                <Grid.Col span={0.8} />
                <Grid.Col span={2.6}>
                    <Text>{t("GROUP_ID")}</Text>
                </Grid.Col>
                <Grid.Col span={7.7}>
                    <div className='textInput'>
                        <TextInput w={"18.5rem"} />
                    </div>
                </Grid.Col>
            </Grid>

            <Grid mt={'md'} align='center'>
                <Grid.Col span={0.8} />
                <Grid.Col span={2.6}>
                    <Text>{t("GROUP_NAME")}</Text>
                </Grid.Col>
                <Grid.Col span={7.7}>
                    <div className='textInput'>
                        <TextInput w={"18.5rem"} />
                    </div>
                </Grid.Col>
            </Grid>

            <Flex justify="flex-end" mt="2.37rem" mb={"lg"} mr={theme.spacing.xxxl}>
                <CustomButton variant={'cancel'} onClick={onClose} children={"CANCEL"} />
                <CustomButton variant={'submit'} children={buttonName} />
            </Flex>
        </Modal>
    );
};

export default FundGroupModalUser;
